<template>
  <div class="lol-tier-info">
    <div class="left-side">
      <img :src="`/img/lol/emblem-${tierPrefix}.svg`" />
    </div>
    <div class="right-side">
      <div class="top">
        <h4 v-if="disabled" class="tier-text">-</h4>
        <h4 v-else-if="tier === 'UNRANKED'" class="tier-text">UNRANKED</h4>
        <h4 v-else class="tier-text">{{ $t(`_.LOL_TIER_${tier}`) }}</h4>
        <span>{{ `${leaguePoints}LP` }}</span>
      </div>
      <div class="bottom">
        <span>{{ formattedWinRate }}&nbsp;</span>
        <span>{{ formattedTotalCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  lexicon: 'coaching',
  name: 'LolTierInfo',
  props: {
    /** ex) 'BRONZE_II', 'GRANDMASTER_I', 'CHALLENGER_I' */
    tier: { type: String, default: 'UNRANKED' },
    leaguePoints: { type: Number, default: 0 },
    winRate: { type: Number, default: 0 },
    totalWinCount: { type: Number, default: 0 },
    totalLoseCount: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    tierPrefix() {
      const t = this.tier?.split('_');
      return t?.[0];
    },
    formattedWinRate() {
      return `승률 ${this.winRate}%`;
    },
    formattedTotalCount() {
      return `(${this.totalWinCount}승 ${this.totalLoseCount}패)`;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

.lol-tier-info { .w(fit-content); .p(10); .flex; .bgc(#fff); box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); .br(4);
  .left-side {
    img {.wh(40); }
  }
  .right-side { .ml(8); .flex; flex-direction: column;
    .top {.flex; .items-center;
      .tier-text {.bold; .fs(16); .c(#191919); }
      span {font-family: 'Roboto'; .fs(12); .c(#191919); .ml(4); .h(100%); .flex; .items-center; }
    }
    .bottom { .lh(10);
      span {.fs(10); .c(#191919); font-family: 'Roboto'; .lh(10); }
      span:nth-of-type(2) { .c(#c4c4c4);}
    }
  }
}
</style>
