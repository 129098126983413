<template>
  <div medal-label>
    <img :src="`/img/coaching/medal-label-${color}.svg`" class="medal-label-img" />
    <label class="medal-label-text">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'MedalLabel',
  props: {
    color: {
      type: String,
      default: 'pink',
    },
    label: {
      default: '',
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[medal-label] { .rel; .w(36); .h(59);
  .medal-label-text { .abs; .t(26); .l(50%); transform: translate(-50%, 0); .chakra; .tc; .bold; .fs(23); .lh(30); .c(#fff) }
  .medal-label-img {.w(36); .h(59);}
}
</style>
