<template>
  <div recommend-result>
    <div v-if="!isDirect" class="top-wrap">
      <h4>
        <img src="/img/coaching/emoji-eyes-star.svg">
        결과를 분석하여 최적의 코치님을 <br v-if="isMobile">찾았습니다!
      </h4>
      <p>원하는 코치님을 선택하기만 하면 <br v-if="isMobile">즉시 상담이 요청됩니다.</p>
      <span class="top-wrap--hint">(선택한 코치님은 자동으로 즐겨찾기 추가됩니다.<br />오프라인이거나 이미 수업중일 경우, 답변이 늦어질 수 있습니다.)</span>
      <span class="top-wrap--hint mobile">(원하는 코치님을 선택하기만 하면<br />즉시 상담이 요청되며 즐겨찾기 추가됩니다.)</span>
    </div>
    <div v-else class="top-wrap">
      <h4>
        <img src="/img/coaching/emoji-eyes-star.svg">
        접속중인 코치님을 찾았습니다!
      </h4>
      <p>{{ recommendationCoachesLength }}명의 코치님께 즉시 상담을 요청할 수 있습니다.</p>
    </div>
    <div class="content-wrap">
      <div class="coach-list">
        <div v-for="(item, i) in recommendationCoaches"
          :key="item.coachId"
          :class="{'consulted': consultedIndexList.includes(i), 'focused': focusedIdMap[item.coach.coachId]}"
          class="coach-item">
          <CoachCardPreview :coachInfo="item.coach" hoverDisabled showRecommendInfo @examine="focusCoach(item.coach.coachId)" />
          <div class="item-overlay">
            <button class="button-close" @click="unfocusCoach(item.coach.coachId)">
              <svg-x />
            </button>
            <button class="button-cta" @click="coachItemHandler(item.coach.coachId, i)">1:1 상담을 요청합니다!</button>
            <router-link :to="getCoachPageRoute(item.coach.coachId)">코치님 정보 상세보기</router-link>
          </div>
          <div class="consulted-label">
            <p>
              <img src="/img/icon/coaching/icon-check-bg-green.svg">
              요청됨
            </p>
          </div>
        </div>
      </div>
      <div v-if="!isDirect" class="coach-finder">
        <p class="coach-finder-text">더 많은 코치님을 알고 싶다면 <a @click="linkHandler">코치찾기</a> 로 이동해보세요!</p>
      </div>
      <div class="btn-holder" :class="{'mt': isDirect, 'pb': isDirect}">
        <BasicButton v-if="!isDirect" theme="grey" @click="prevStep">
          <p>
            <img class="arrow-dark" src="/img/icon/icon-arrow-down-dark.svg" />
            이전
          </p>
        </BasicButton>
        <BasicButton v-else theme="grey" class="w-106" @click="goMain">
          <p>
            <img class="arrow-dark" src="/img/icon/icon-arrow-down-dark.svg" />
            처음으로
          </p>
        </BasicButton>
      </div>
      <div v-if="isDirect" class="coach-tv">
        <hr />
        <h2>기다리는 동안 샘플강의 보기</h2>
        <p class="description">🔥 지금 핫한 코치TV 인기 영상으로 나혼자만 레벨업!</p>
        <div class="playlist">
          <CoachTvPlaylistCard v-for="(item, i) in _coachTvPlaylist" :key="i" :dateTime="item.contentCreatedDatetime" :thumbnailUrl="item.thumbnailUrl"
            :title="item.title" @click="coachTvItemHandler(item)" />
        </div>
      </div>
    </div>
    <div v-show="_showChatTooltip" class="chat-tooltip" @click="showChatTooltip = false">
      <p>채팅을 통해 코치님이 답변해 주실거예요!</p>
      <button>
        <img src="/img/manage/icon-close-white.svg">
      </button>
    </div>
  </div>
</template>
<script>
import { state } from '@shared/utils/storeUtils';
import SvgX from '@shared/graphics/svg-x-alt.vue';
import MovModal from '@/views/components/common/modal/MovModal.vue';
import CoachCardPreview from '@/views/components/coaching/CoachCardPreview.vue';
import CoachTvPlaylistCard from '@/views/components/coaching/coachTv/CoachTvPlaylistCard.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import coach from '@/mixins/coach';

export default {
  name: 'RecommendResult',
  components: { BasicButton, CoachCardPreview, SvgX, CoachTvPlaylistCard },
  mixins: [coach],
  data: () => ({
    consultedIndexList: [],
    focusedIdMap: {},
    showChatTooltip: true,
    coachTvPlaylist: [],
  }),
  props: {
    recommendationResult: {
      default: () => ({}),
    },
    isDirect: {
      type: Boolean, default: false,
    },
  },
  watch: {
    recommendationCoaches: {
      immediate: true,
      handler(coaches) {
        this.consultedIndexList = [];
        for (let i = 0; i < coaches?.length; i++) {
          if (coaches[i]?.isConsulted) this.consultedIndexList?.push(i);
        }
      },
    },
  },
  computed: {
    _coachTvPlaylist() {
      if (this.isTablet) return this.coachTvPlaylist.slice(0, 3);
      return this.coachTvPlaylist;
    },
    channelList: state('chat', 'channelList'),
    isChattingRoomExist() {
      return this.channelList.length > 0;
    },
    _showChatTooltip() {
      return this.showChatTooltip && this.isChattingRoomExist && this.isDirect && this.consultedIndexList?.length;
    },
    _recommendationResult() {
      return /** @type {RecommendationCoachesResult || RecommendationCoach} */ this.recommendationResult;
    },
    recommendationCoaches() {
      return this._recommendationResult?.recommendationCoaches;
    },
    recommendationCoachesLength() {
      return this._recommendationResult?.recommendationCoaches?.length;
    },
    isMobile() {
      return this.matchedMediaDevice === 'M';
    },
    isTablet() {
      return this.matchedMediaDevice === 'T';
    },
  },
  methods: {
    coachTvItemHandler(item) {
      this.$modal(MovModal, { mov: item.contentUrl });
    },
    linkHandler() {
      const host = process.env.VUE_APP_HOME_URL;
      const path = this.$router.resolve({ name: 'CoachFinder' })?.href;
      const url = `${host}${path}`;
      window.open(url, '_blank');
      this.$gtag.event('open_coach_finder_from_coach_recommend', {
        event_category: 'coach_recommend',
        event_label: 'open_coach_finder_from_coach_recommend',
      });
    },
    focusCoach(coachId) {
      this.$set(this.focusedIdMap, coachId, true);
    },
    coachExamineHandler(coachId) {
      this.openTutor(coachId);
    },
    unfocusCoach(coachId) {
      this.$delete(this.focusedIdMap, coachId);
    },
    async coachItemHandler(coachId, i) {
      this.$gtag.event('click_coach_recommend_card', {
        event_category: 'coach_recommend',
        event_label: 'click_coach_recommend_card',
        value: coachId,
      });
      const isFirstChat = Boolean(!this.$store.getters['chat/hasCoachDM']);
      this.$gtag.event(isFirstChat ? 'chat_with_coach_first_time' : 'chat_with_coach', {
        category: 'sendbird_chatting',
        event_label: 'chat_by_recommend_coach',
        value: coachId,
      });
      if (!this.$store.state.auth?.myInfo) {
        await this.$services.auth.oAuthLogin(true);
        return;
      }
      try {
        if (!this.isDirect) await this.$services.coaching.requestCoachConsult(coachId);
        else await this.$services.coaching.requestDirectCoachConsult(coachId);
        if (!this.consultedIndexList.includes(i)) this.consultedIndexList?.push(i);
        this.$services.toast.toast('coaching.coachMatching.goRequest.result.success');
        const coachDetail = await this.$services.coaching.getTutorDetail(coachId);
        if (!coachDetail?.isLiked) await this.$services.coaching.likeCoach(coachId, false);
      } catch (err) {
        const errMsg = err?.[0];
        if (err?.[1] === 'UNAUTHORIZED') {
          throw err;
        } else if (String(errMsg).includes('coachMatching.goRequest.result.fail')) this.$services.toast.toast(errMsg, { type: 'fail' });
        else throw err;
      }
    },
    prevStep() {
      window.scrollTo(0, 0);
      this.$emit('prevSection');
    },
    goMain() {
      window.scrollTo(0, 0);
      this.$emit('goMain');
    },
    async getCoachTvPlaylist() {
      const q = { isRecommend: true, size: 4 };
      const result = await this.$services.coaching.getCoachTvContentList(q);
      this.coachTvPlaylist = result?.items || [];
    },
  },
  mounted() {
    this.getCoachTvPlaylist();
  },
};
</script>
<style lang="less">
@import '~@/less/coaching.less';
[recommend-result] {
  > .top-wrap { .flex; .items-center; flex-direction: column;
    > h4 {.flex; .items-center; .fs(24); .bold;
      > img {.wh(28); .mr(8);}
    }
    > p {.fs(24);}
  }
  .top-wrap--hint {.c(#777); .fs(14); .mt(12); text-align: center;}
  .content-wrap {
    .coach-list { .flex; .pt(64); column-gap: 16px; .max-w(calc(100vw)); overflow-x: scroll; overflow-y: hidden; .pb(20);
      &::-webkit-scrollbar {
        height: 4px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #aaa;
      }
      .coach-item { .rel;
        &:hover:not(.focused) [coach-card-preview] {border: solid 1px #191919;}
        .item-overlay {.hide}
        &.focused:not(.consulted) {
          [coach-card-preview] .preview-profile-img-wrap { .z(10); }
          .item-overlay {.bgc(rgba(0, 0, 0, 0.8)); .w(308); .h(390); .abs; .br(20); .t(50); .flex; .items-center; .justify-center; flex-direction: column;
            .button-close { .abs;.rt(27, 27);.wh(10);}
            .button-close svg path {stroke: white; shape-rendering: geometricPrecision;}
            a { .block;.c(white);.fs(14);.lh(20);text-decoration-line: underline; pointer-events: auto;}
          }
        }
        .consulted-label {.hide;}
        &.consulted { .rel; pointer-events: none;
          .consulted-label { .abs;.w(93); .h(34); .bgc(#0ce757); .flex; .items-center; .justify-center; .br(50); .l(0); .r(0); .ml(auto); .mr(auto); .b(-12);
            > p {.c(#fff); .flex;
              > img {.wh(18); .mr(4);}
            }
          }
          [coach-card-preview] {border: 2px solid #0ce757; }
        }
      }
    }
  }
  .item-overlay .button-cta {.bgc(#d40404);.p(16, 24);.fs(16);.block;.c(white);.mb(12);font-weight: 500; pointer-events: auto;.br(4);
    &:hover {.bgc(#b40000);}
    &:disabled {.bgc(#191919);.c(rgba(0, 0, 0, .5);)}
    &:focus {border: solid 1px #2c81ff;}
  }
  .coach-finder { .mt(60); .flex; .justify-center;
    .coach-finder-text { .fs(18);
      > a {.c(#2c81ff); text-decoration: underline; text-underline: #2c81ff; }
    }
  }
  .btn-holder {.flex; .space-between; .mt(50); .pb(154);
    &.mt {.mt(100);}
    &.pb {.pb(0);}
    > [basic-button] { .h(40); .p(0, 16);
      &.w-106 {.w(106);}
      p {.mr(4); .flex; .items-center;
        .arrow-dark {transform: rotate(90deg); .wh(18);}
      }
    }
  }
  .coach-tv { .pb(160);
    > hr {.w(100%); .h(1); .bgc(#ebebeb); .m(100, 0);}
    > h2 {.fs(28); .c(#191919);}
    .description {.w(fit-content); .mt(10); .mb(20); .bgc(#faf2ff); .p(2, 8); .fs(13);}
    .playlist { .flex; gap: 16px;
    }
  }
  .chat-tooltip { .rel; .bgc(#191919); .fix(); .b(134); .r(114); .w(167); .h(72); .br(8); .p(16); .z(1); filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2)); cursor: pointer;
    animation: shake 2.5s infinite;
    transform: translate(2px);
    > p {.fs(14); .c(#fff);}
    > button { .abs; .r(4); .t(6);}
    &:after { content: ''; .abs(); .r(-9); .t(34); .wh(0);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #191919;
      clear: both;
      transform: rotate(-90deg);
    }
    @keyframes shake {
      0% { transform: translate(2px) }
      20% { transform: translate(-2px) }
      40% { transform: translate(2px) }
      60% { transform: translate(-2px) }
      80% { transform: translate(2px) }
    }
  }
  .top-wrap--hint.mobile {.hide}
  @media (@ml-down) {
    .top-wrap--hint:not(.mobile) {.hide}
    .top-wrap--hint.mobile {display: unset;}
    .top-wrap { text-align: center;
      > h4 { .fs(20); .flex; flex-direction: column;
        > img {.mb(12);}
      }
      > p {.fs(16); .mt(12);}
    }
    .coach-finder {
      .coach-finder-text { .fs(14);
      }
    }
    .item-overlay .button-cta {.p(12, 18)}
    .coach-tv {
      > hr { .m(56, 0);}
      .playlist { flex-direction: column; gap: 24px;}
    }
  }
}
</style>
