<template>
  <section recommend-question-step-2>
    <div class="items-text">
      <span>(중복 선택 가능)</span>
    </div>
    <div class="questions">
      <div v-for="(item, i) in questionItems" :key="i" class="question-item">
        <BasicButton :selected="selectedItemIndexList.includes(i)" @click="itemClickHandler(i, item)">{{ item.text }}</BasicButton>
        <ValidationProvider v-slot="{errors}" name="answerText" rules="lengthMin:0|lengthMax:100">
          <MemoTextArea v-if="item.type === 'text' && selectedItemIndexList.includes(i)" ref="answerInput" v-model="_answerText" :hasError="!!errors.length" :maxLength="100" :minLength="0" :placeholder="''" />
        </ValidationProvider>
      </div>
    </div>
    <div class="btn-wrap">
      <BasicButton theme="grey" @click="$emit('prevBtn')">
        <p>
          <img class="arrow-dark" src="/img/icon/icon-arrow-down-dark.svg" />
          이전
        </p>
      </BasicButton>
      <BasicButton v-if="showNextBtn" theme="dark" @click="$emit('nextBtn')" class="w-84 next">
        <p>다음
          <img class="arrow-white" src="/img/icon/icon-arrow-down-white.svg" />
        </p>
      </BasicButton>
    </div>
  </section>
</template>

<script>
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import MemoTextArea from '@/views/components/common/input/MemoTextArea.vue';

export default {
  name: 'RecommendQuestionStep2',
  components: { BasicButton, MemoTextArea },
  props: {
    step: {
      type: Number, default: 0,
    },
  },
  data: () => ({
    selectedItemIndexList: [],
    answerText: '',
    hasAnswerTextError: false,
  }),
  computed: {
    questionItems() {
      return [
        { text: '코치님이 알아서 체계적으로 정해주시면 좋겠어요', type: 'button' },
        { text: '제가 ‘실시간으로 플레이하는 영상’을 보면서 코칭해주세요!', type: 'button' },
        { text: '제가 플레이 했던 ‘리플레이 영상’을 보면서 코칭해주세요!', type: 'button' },
        { text: '맞춤형 수업을 받고싶어요', type: 'text' }];
    },
    _answerText: {
      get() {
        return this.answerText;
      },
      set(v) {
        this.hasAnswerTextError = false;
        if (v?.length > 100) this.hasAnswerTextError = true;
        this.answerText = v;
      },
    },
    showNextBtn() {
      return this.selectedItemIndexList.length && !this.hasAnswerTextError;
    },
  },
  methods: {
    itemClickHandler(i, item) {
      const idx = this.selectedItemIndexList.indexOf(i);
      if (idx > -1) {
        this.$delete(this.selectedItemIndexList, idx);
        if (item?.type === 'text') {
          this.answerText = '';
          this.hasAnswerTextError = false;
        }
      } else {
        this.selectedItemIndexList.push(i);
        if (item?.type === 'text') {
          this.$nextTick(() => {
            this.$refs?.answerInput?.[0]?.onTipClick();
          });
        }
      }
    },
    pushAnswer(step) {
      const answers = this.selectedItemIndexList.map(i => {
        let value = '';
        if (this.questionItems?.[i]?.type === 'text') value = this.answerText;
        return { number: i + 1, value, text: this.questionItems?.[i]?.text };
      });
      const answer = { number: step, question: '', answers };
      this.$emit('answer', { step, answer });
    },
    setAnswer(_answer) {
      this.selectedItemIndexList = [];
      const { answers } = _answer;
      for (let i = 0; i < answers?.length; i++) {
        const idx = (answers[i] ?? {}).number - 1;
        if (this.questionItems?.[idx]?.type === 'text') {
          this.answerText = answers[i]?.value;
        }
        this.selectedItemIndexList.push(idx);
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[recommend-question-step-2] {
  .items-text { .mb(12);
    > p {.fs(18); .c(#191919); .mr(8); }
    > span {.fs(12); .c(#aaa); }
  }
  .questions { .flex; flex-direction: column; gap: 16px;
    .question-item { .flex; gap: 16px;
      [basic-button] {.w(fit-content); .fs(14); .min-w(182); text-align: left; line-break: anywhere; .h(unset); .min-h(50); .pl(16); .pr(16);}
      [memo-text-area] {.h(98); .w(100%); .br(4);
        &.wrap-textarea > textarea {.h(unset);}
      }
    }
  }

  .btn-wrap {.abs; .b(40); .w(100%); .flex; .space-between;
    > [basic-button] { .h(40); .w(80);
      &.w-84 {.w(84);}
      p {.mr(4); .flex; .items-center; .fs(14);
        .emoji {.mr(4); }
        .arrow-dark {transform: rotate(90deg); .wh(18);}
        .arrow-white {transform: rotate(-90deg); .wh(18);}
      }
    }
  }

  @media (@ml-down) {
    .questions {
      .question-item { .ml(0); flex-direction: column;
        [basic-button] {.fs(14); .pt(12); .pb(12); .h(unset); .min-h(unset);}
      }
    }

    .btn-wrap { justify-content: unset;
      > [basic-button] {
        &.next {.ml(8);}
        p {.fs(14);}
      }
    }
  }
}
</style>
