<template>
  <div choose-ab-list>
    <div v-for="(item, i) in list" :key="i" :class="{disabled: i > step, active: i === step}" class="choose-ab-item">
      <BasicButton :selected="answerList && answerList[i] && answerList[i].answer === 'A'" @click="itemClickHandler('A', item, i)">
        <pre>{{ item.textA }}</pre>
      </BasicButton>
      <span :class="{active: i === step}">VS</span>
      <BasicButton :selected="answerList && answerList[i] && answerList[i].answer === 'B'" @click="itemClickHandler('B', item, i)">
        <pre>{{ item.textB }}</pre>
      </BasicButton>
    </div>
  </div>
</template>

<script>
import BasicButton from '@/views/components/common/button/BasicButton.vue';

export default {
  name: 'ChooseABList',
  components: { BasicButton },
  props: {
    list: { type: Array, default: () => ([{ textA: '', textB: '' }]) },
    answerList: { type: Array, default: () => ([{ answer: '', item: {} }]) },
  },
  computed: {
    step() {
      return this.answerList?.length;
    },
  },
  methods: {
    itemClickHandler(answer, item, index) {
      this.$emit('answer', { answer, item, index });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[choose-ab-list] {
  .choose-ab-item {.flex; .items-center; .mb(16);
    [basic-button] {.w(100%); .h(50); .fs(14); transition: all ease-in 0.2s; transition-property: height, font-size;
      > pre {white-space: pre-wrap; .ellipsis(4);}
    }
    &.active {
      [basic-button] {.h(150); .c(#777); .fs(18); }
    }
    &.disabled { pointer-events: none;
      [basic-button] {.bgc(#eaeaea); .c(#c4c4c4);}
    }
  }
  span {.m(0, 20); .c(#c4c4c4); .fs(14); font-family: 'Roboto';
    &.active {.c(#000); .fs(18); .bold; }
  }

  @media (@ml-down) {
    .choose-ab-item {
      [basic-button] {.h(72); .fs(12); .pl(6); .pr(6);}
      &.active {
        [basic-button] { .fs(14); }
      }
      &.disabled { pointer-events: none;
        [basic-button] {.fs(12); .h(72);}
      }
    }
  }
}

</style>
