<template>
  <div mov-modal>
    <div class="dim" @close="close" />
    <div class="panel">
      <div class="frame" @click="close">
        <div class="holder">
          <button @click="close">
            <svg-x />
          </button>
          <iframe v-if="options&&options.mov" :src="options.mov" allowfullscreen="true" scrolling="no"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgX from '@shared/graphics/svg-x.vue';

export default {
  name: 'MovModal',
  components: { SvgX },
  props: {
    options: null,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[mov-modal] {
  > .dim { .abs; .f; .bgc(@c-b07); .lt; }
  > .panel { .wh(100%, 100vh);
    .frame { height: 0; .abs; .w(100%); .lt(50%, 50%); .t-xyc; padding-top: calc(591.44 / 1127.34 * 100%);
      .holder { .abs; .z(100); .lt(50%, 50%); .t-xyc; .wh(80%); .max-w(1000); .max-h(500);
        > button { .wh(36); .abs; .rt(0, -40); .z(100);
          svg { .wh(100%); .stroke(white); }
        }
        iframe { .wh(100%); .mh-c; .block;}
      }
    }
  }
}
</style>
