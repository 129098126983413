<template>
  <div :class="{disabled: disabled, 'on':isShowFilter}" champ-selector>
    <div class="champ-selector-inner" @click="showFilterHandler">
      <div class="champ-info">
        <p v-if="!selectedChampCode">챔피언 선택</p>
        <span v-else>
          <cdn-img :src="selectedChampImage" class="champ-img" />
          <p class="champ-name">{{ selectedChampName }}</p>
        </span>
      </div>
      <img class="arrow-icon" src="/img/icon/icon-arrow-down-grey.svg" />
    </div>
    <div v-show="isShowFilter" class="filter-champ-wrap">
      <div class="filter-title-wrap">
        <div>
          <h4 class="filter-text">{{ $t('coaching.BT_lol_cham') }}</h4>
          <!--          <p class="filter-sub-text">(최근 20게임 데이터)</p>-->
        </div>
        <SvgX class="close-icon" @click="showFilterHandler" />
      </div>
      <WinRateFilterChamp v-model="selectedChampCode" :champs="champs" @champ="selectChamp" />
    </div>
    <div class="dim" />
  </div>
</template>

<script>
import CdnImg from 'shared/components/common/CdnImg.vue';
import SvgX from '@shared/graphics/svg-x.vue';
import WinRateFilterChamp from '@/views/components/coaching/filter/WinRateFilterChamp.vue';

export default {
  name: 'ChampSelector',
  components: { WinRateFilterChamp, CdnImg, SvgX },
  props: {
    disabled: {
      type: Boolean, default: false,
    },
    champs: {
      type: Array, default: () => ([]),
    },
    value: {
      type: String, default: '',
    },
  },
  data: () => ({
    isShowFilter: false,
  }),
  computed: {
    selectedChampCode: {
      get() {
        return this.value;
      },
      set(code) {
        this.$emit('input', code);
        this.isShowFilter = false;
      },
    },
    selectedChamp() {
      if (this.selectedChampCode) {
        for (let i = 0; i < this.champs.length; i++) {
          if (this.champs[i]?.code === this.selectedChampCode) return this.champs[i];
        }
      }
      return {};
    },
    selectedChampName() {
      return this.selectedChamp?.koName;
    },
    selectedChampImage() {
      return this.selectedChamp?.imageUrl;
    },
  },
  methods: {
    showFilterHandler() {
      this.isShowFilter = !this.isShowFilter;
    },
    selectChamp(champ) {
      this.$emit('selectChamp', champ);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[champ-selector] { .w(fit-content); .rel;
  &.disabled { .bgc(#eaeaea); pointer-events: none;
    .champ-selector-inner {
      .champ-info {.o(0.2);}
    }
  }
  .champ-selector-inner {
    .flex; .items-center; justify-content: space-between; .w(171); .h(48); border: 1px solid #d5d5de; .br(4); .p(0, 12); .pointer; .rel;
    &:hover {border: 1px solid #2c81ff; }
    .champ-info { .flex; .items-center;
      > span {.flex; .items-center;}
      .champ-name {.ml(4);}
      .champ-img {.wh(32); .br(4); border: 1px solid #fff;}
    }
    .arrow-icon {.wh(24);}
  }
  .filter-champ-wrap {.abs; .t(60); .w(359); .p(20, 30, 30); .bgc(#fff); .br(20); box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); .z(100);
    .filter-title-wrap { .c(#191919); .lh(16); .flex; .mb(20); justify-content: space-between;
      > div {.flex; .items-center;}
      > div > .filter-text { .fs(14); .bold; }
      > div > .filter-sub-text { .ml(4); .fs(12); .c(#aaa);}
      .close-icon {
        .stroke-target { stroke: #191919; }
        .pointer;
      }
    }
  }
  .dim {.fix; .wh(100%); .t(0); .l(0); .bgc(rgba(0, 0, 0, 0.7)); .z(9999); .hide; }
}

</style>
