<template>
  <div flag-label>
    <label class="flag-label-text">{{ label }}</label>
    <img src="/img/coaching/flag-label.svg" class="flag-label-img" />
  </div>
</template>

<script>
export default {
  name: 'FlagLabel',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[flag-label] { .rel; .w(36px); .h(59px);
  .flag-label-text { .abs; top: 18px; left: 50%; transform: translate(-50%, 0); .chakra; .bold; .fs(18); .lh(23); text-align: center; .c(#fff); }
  .flag-label-img { .w(36); .h(59) }
}
</style>
