<template>
  <div win-rate-filter-champ>
    <TextInput v-model="keyword" class="input-champ-keyword" placeholder="챔피언을 검색하세요" />
    <div class="champ-list">
      <div
        v-for="champ in _champs"
        :key="`coach-filter-item-${champ.code}`"
        class="champ-item"
        @click="() => onChampClick(champ)"
      >
        <div>
          <cdn-img :src="champ.imageUrl" class="champ-icon" d="64x64" />
          <span>{{ champ.koName }}</span>
        </div>
        <div class="rate-wrap">
          <div class="rate-bar-wrap">
            <div class="rate-bar-bg" />
            <div v-if="champ.totalPickCount" class="rate-bar">
              <div :style="{width: `${champ.winRate}%`}" class="win-rate" />
              <span v-show="champ.totalWinCount">{{ `${champ.totalWinCount}${$t('coaching.MS_win')}` }}</span>
              <span v-show="champ.totalLoseCount">{{ `${champ.totalLoseCount}${$t('coaching.MS_loss')}` }}</span>
            </div>
          </div>
          <p class="pick-rate">{{ champ.winRate }}%</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';

export default {
  components: { TextInput, CdnImg },
  data: () => (
    {
      keyword: '',
    }),
  props: {
    value: {
      type: String,
      default: '',
    },
    champs: {
      /** @type {LOlChampWinRate[]} */
      type: Array, default: () => ([]),
    },
  },
  computed: {
    _champs() {
      let champs = [];
      if (this.keyword) {
        champs = this.champs?.filter(champ => {
          if (champ?.koName?.includes(this.keyword)) return champ;
          return false;
        });
      } else {
        champs = this.champs;
      }
      return champs;
    },
  },
  methods: {
    onChampClick(champ) {
      this.$emit('input', champ.code);
      this.$emit('champ', champ);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[win-rate-filter-champ] {.noto;
  .input-champ-keyword {.mb(11);}
  .champ-list { .h(336);.br(12);overflow-y: scroll;box-sizing: border-box;border: solid 1px #d5d5de;}
  .champ-item { .h(56);.flex;.flex-ai(center);.p(12);box-sizing: border-box; justify-content: space-between;
    > div {.flex; .items-center;}
    > div > .champ-icon {.wh(32, 32);.br(4);.mr(8);border: 1px solid #fff;box-sizing: border-box;}
    > div > span {.fs(14);.c(@c-title-black);}
    &:hover { .bgc(rgba(164, 163, 174, 0.1));}
  ;}
  .rate-wrap { .rel;
    .rate-bar-wrap { .rel; .br(2); .w(118); .h(18);
      .rate-bar-bg {.abs; .t(0); .l(0); .w(84); .h(18); .bgc(#ddd); .br(2);}
      .rate-bar { .rel; .abs; .t(0); .l(0); .w(84); .h(18); .bgc(#ff4667); .br(2);
        .win-rate {.abs; .l(0); .bgc(#2c81ff); .wh(inherit); border-radius: 2px 0 0 2px; }
        > span {
          &:nth-of-type(1) {.t(4); .l(4);}
          &:nth-of-type(2) {.t(4); .r(4);}
          .abs; .fs(9); .lh(9); .c(#fff); font-family: 'Roboto' }

      }
    }
    .pick-rate {.abs; .r(0); .c(#191919); .fs(10); .lh(10);}
  }
}
</style>
