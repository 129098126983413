<template>
  <section recommend-question-step-4>
    <div class="items-text">
      <span>(중복 선택 가능)</span>
    </div>
    <div class="question-item-wrap">
      <div v-for="(item, i) in questionList" :key="i" class="question-item">
        <BasicButton :selected="selectedItemIndexList.includes(i)" @click="itemClickHandler(i)">{{ item }}</BasicButton>
      </div>
    </div>
    <div class="btn-wrap">
      <BasicButton theme="grey" @click="$emit('prevBtn')">
        <p>
          <img class="arrow-dark" src="/img/icon/icon-arrow-down-dark.svg" />
          이전
        </p>
      </BasicButton>
      <BasicButton v-if="showNextBtn" theme="dark" @click="$emit('nextBtn')" class="w-84 next">
        <p>다음
          <img class="arrow-white" src="/img/icon/icon-arrow-down-white.svg" />
        </p>
      </BasicButton>
    </div>
  </section>
</template>

<script>
import BasicButton from '@/views/components/common/button/BasicButton.vue';

export default {
  name: 'RecommendQuestionStep4',
  components: { BasicButton },
  props: {
    step: {
      type: Number, default: 0,
    },
  },
  data: () => ({
    selectedItemIndexList: [],
    questionList: ['수강생 만족도가 높은', '코칭 경험이 많은', '초보자들을 잘 이해해주는', '새로운 정보를 많이 알고 있는', '브실골 탈출 노하우가 가득한', '플래 탈출 노하우가 가득한'],
  }),
  computed: {
    showNextBtn() {
      return this.selectedItemIndexList.length;
    },
  },
  methods: {
    itemClickHandler(i) {
      const idx = this.selectedItemIndexList.indexOf(i);
      if (idx > -1) {
        this.$delete(this.selectedItemIndexList, idx);
      } else this.selectedItemIndexList.push(i);
    },
    pushAnswer(step) {
      const answers = this.selectedItemIndexList.map(i => {
        const value = '';
        return { number: i + 1, value, text: this.questionList?.[i] };
      });
      const answer = { number: step, question: '', answers };
      this.$emit('answer', { step, answer });
    },
    setAnswer(_answer) {
      this.selectedItemIndexList = [];
      const { answers } = _answer;
      for (let i = 0; i < answers?.length; i++) {
        this.selectedItemIndexList.push((answers[i] ?? {}).number - 1);
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[recommend-question-step-4] {
  .items-text { .mb(12);
    > p {.fs(18); .c(#191919); .mr(8); }
    > span {.fs(12); .c(#aaa); }
  }

  .question-item-wrap { .flex; flex-wrap: wrap; row-gap: 16px; column-gap: 20px;
    .question-item { .flex;
      [basic-button] { .fs(14); .h(unset); .min-h(50); .pt(12); .pb(12); .pl(16); .pr(16);}
    }
  }

  .btn-wrap {.abs; .b(40); .w(100%); .flex; .space-between;
    > [basic-button] { .h(40); .w(80);
      &.w-84 {.w(84);}
      p {.mr(4); .flex; .items-center; .fs(14);
        .emoji {.mr(4); }
        .arrow-dark {transform: rotate(90deg); .wh(18);}
        .arrow-white {transform: rotate(-90deg); .wh(18);}
      }
    }
  }

  @media (@ml-down) {
    .question-item-wrap {
      .question-item { .flex; .w(100%);
        [basic-button] {.w(100%); .fs(14); .min-h(unset);}
      }
    }

    .btn-wrap { justify-content: unset;
      > [basic-button] {
        &.next {.ml(8);}
        p {.fs(14);}
      }
    }
  }
}
</style>
