<template>
  <div :class="{'bgc-w': section === 'result' || section === 'direct'}" coach-recommend>
    <div v-show="section ==='main'" class="inner-wrap">
      <div class="section-wrap page-border">
        <div class="content">
          <div class="inner main">
            <div class="desc-wrap">
              <p>
                <img src="/img/coaching/emoji-eyes-star.svg" />
                {{ $t('_.description1') }}
              </p>
              <p>{{ $t('_.description2') }}</p>
            </div>
            <ValidationProvider v-slot="{errors}" :rules="'lengthMin:3'" class="input-wrap" name="summonerName">
              <CoachSearchInput v-model="_summonerName" :hasError="!!errors.length || hasSummonerNameError" :place-holder="$t('_.accountInputPlaceholder')" class="search-input" @search="recommendationBtnHandler" />
              <div class="error-text-wrap">
                <p v-if="hasSummonerNameError" class="error-text">{{ $t('_.pleaseSummonerNameCheck') }}</p>
                <ValidationErrors v-else :errors="errors" class="error-text" />
              </div>
              <BasicButton :disabled="!summonerName.length || !!errors.length" class="recommendation-btn" theme="dark" @click="recommendationBtnHandler">{{ $t('_.getRecommendation') }}</BasicButton>
            </ValidationProvider>
            <hr class="divider" />
            <div class="btn-holder">
              <BasicButton @click="noSummonerBtnHandler">{{ $t('_.noAccountBtn') }}</BasicButton>
              <BasicButton @click="consultBtnHandler">{{ $t('_.consultCoach') }}</BasicButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="section === 'question'" class="inner-wrap">
      <div class="section-wrap page-border">
        <div class="content">
          <div class="inner question">
            <RecommendQuestions ref="questions" v-bind="{answers, isHigherTier, maxStep: maxQuestionStep, step: questionStep, summonerInfo, hasRiotError }" @answer="answerHandler" @answerStorage="setAnswerIntoStorage" @nextStep="nextQuestionStep" @prevStep="prevQuestionStep" @setStep="(v) => questionStep = v" />
          </div>
        </div>
      </div>
    </div>
    <div v-show="section === 'result' || section === 'direct'" class="result-inner-wrap">
      <RecommendResult :isDirect="section === 'direct'" v-bind="{recommendationResult}" @prevSection="goQuestionSection" @goMain="directToMainSection" />
    </div>
    <div v-if="section === 'searching'" class="searching-wrap">
      <div class="holder">
        <p class="text"><em>접속중인 코치님을 찾고 있어요!</em></p>
        <div><p class="text">잠시만 기다려주세요!</p>
          <img src="/img/coaching/emoji-monocle.svg">
        </div>
        <Lottie class="loading" src="/lottie/loading-red-circle.json" autoplay loop />
      </div>
    </div>
    <div v-if="section === 'notFound'" class="not-found-wrap">
      <div class="holder">
        <p class="text">
          <img src="/img/coaching/emoji-tear.svg">
          <em>앗! 아쉬워요<br v-if="isMobile"> 현재 접속중인 코치님을 찾을 수 없네요</em></p>
        <p class="text">몇 가지만 알려주시면 내게 딱 맞는 코치님을 찾아드릴게요!</p>
        <BasicButton class="go-main-btn" theme="dark" @click="directToMainSection">
          <p>
            <img class="emoji" src="/img/coaching/emoji-twinkle.svg">
            내게 딱 맞는 코치님 확인하기
            <img class="arrow-white" src="/img/icon/icon-arrow-down-white.svg" />
          </p>
        </BasicButton>
      </div>
    </div>
  </div>
</template>

<script>
import { getter } from 'shared/utils/storeUtils';
import { getPersistentStorage } from 'shared/modules/ObjectStorage';
import Lottie from '@shared/components/general/Lottie.vue';
import { sleep } from '@shared/utils/commonUtils';
import CoachSearchInput from '@/views/components/coaching/CoachSearchInput.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import RecommendQuestions from '@/views/components/coaching/recommend/RecommendQuestions.vue';
import RecommendResult from '@/views/components/coaching/recommend/RecommendResult.vue';

export default {
  name: 'CoachRecommend',
  lexicon: 'coaching.coachRecommend',
  components: { RecommendQuestions, RecommendResult, BasicButton, CoachSearchInput, Lottie },
  data: () => ({
    section: 'init', // 'main', 'question', 'result', 'init', 'direct', 'notFound'
    questionStep: 1,
    hasSummonerNameError: false,
    summonerName: '',
    summonerInfo: {},
    answers: [],
    recommendationResult: {},
    hasRiotError: false,
    intervalId: null,
  }),
  metaInfo() {
    return {
      title: this.$t('meta.coachMatching.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.coachMatching.title') },
        { vmid: 'description', content: this.$t('meta.coachMatching.description') },
        { vmid: 'url', content: this.$t('meta.coachMatching.url') },
        { vmid: 'keywords', content: this.$t('meta.coachMatching.keywords') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  computed: {
    isLogin: getter('auth', 'isLogin'),
    _summonerName: {
      get() {
        return this.summonerName;
      },
      set(v) {
        this.summonerName = v;
        this.hasSummonerNameError = false;
      },
    },
    isMobile() {
      return this.matchedMediaDevice === 'M';
    },
    noSummoner() {
      /**
       * 소환사명이 없음 분기 체크.
       * riot api issue 분기에서도 profile 값이 없음.
       */
      return !this.summonerInfo?.profile && !this.hasRiotError;
    },
    maxQuestionStep() {
      if (this.hasRiotError) return 7;
      if (this.noSummoner) return 5;
      return this.isHigherTier ? 5 : 7;
    },
    isHigherTier() {
      if (!this.summonerInfo?.profile?.tier) return false;
      const [_tier, rank] = this.summonerInfo?.profile?.tier?.split('_') ?? [];
      const tiers = ['DIAMOND', 'MASTER', 'GRANDMASTER', 'CHALLENGER'];
      if (tiers.includes(_tier)) {
        if (_tier === 'DIAMOND') {
          return Number(rank) === 1;
        }
        return true;
      }
      return false;
    },
    _summonerInfo() {
      return /** @type {SummonerRankInfoDetail} */ this.summonerInfo;
    },
  },
  methods: {
    setSummonerNameIntoStorage() {
      getPersistentStorage('coach-recommend').set('nickname', this.summonerName);
    },
    clearSummonerNameStorage() {
      getPersistentStorage('coach-recommend').remove('nickname');
    },
    setStepIntoStorage() {
      getPersistentStorage('coach-recommend').set('step', this.questionStep);
    },
    clearStepStorage() {
      getPersistentStorage('coach-recommend').remove('step');
    },
    setAnswerIntoStorage() {
      getPersistentStorage('coach-recommend').set('answers', this.answers);
    },
    clearAnswerStorage() {
      getPersistentStorage('coach-recommend').remove('answers');
    },
    setPrevSurveyIntoStorage() {
      this.setStepIntoStorage();
      this.setSummonerNameIntoStorage();
      this.setAnswerIntoStorage();
    },
    clearPrevSurveyStorage() {
      this.clearSummonerNameStorage();
      this.clearStepStorage();
      this.clearAnswerStorage();
    },
    makeNoSummonerInfo() {
      return {
        profile: null,
        lane: [],
        champions: [],
        version: 'LOL_V1',
      };
    },
    noSummonerBtnHandler() {
      this.summonerName = '';
      this.summonerInfo = this.makeNoSummonerInfo();
      this.section = 'question';
      this.questionStep = 1;
      this.hasRiotError = false;

      this.$gtag.event('click_coach_recommend_no_summoner_btn', {
        event_category: 'coach_recommend',
        event_label: 'click_coach_recommend_no_summoner_btn',
      });
    },
    async consultBtnHandler() {
      this.recommendationResult = {};
      if (!this.isLogin) {
        await this.$services.auth.oAuthLogin(true);
        return;
      }
      this.$gtag.event('click_coach_recommend_consult_btn', {
        event_category: 'coach_recommend',
        event_label: 'click_coach_recommend_consult_btn',
      });
      this.section = 'searching';
      this.searchingRecommendationCoach();
    },
    async searchingRecommendationCoach() {
      this.getDirectRecommendationCoaches();
      await sleep(3000);
      if (this.recommendationResult?.recommendationCoaches) this.intervalId = setInterval(this.directConsultItvHandler, 500);
      else if (this.recommendationResult?.recommendationCoaches?.length) this.section = 'direct';
      else this.section = 'notFound';
    },
    directConsultItvHandler() {
      if (this.recommendationResult?.recommendationCoaches) {
        clearInterval(this.intervalId);
        if (this.recommendationResult?.recommendationCoaches?.length) this.section = 'direct';
        else this.section = 'notFound';
      }
    },
    async getDirectRecommendationCoaches() {
      this.recommendationResult = await this.$services.coaching.getDirectRecommendationCoaches();
    },
    async getSummonerRankDetailInfo(summonerName, silent = true) {
      const summonerInfo = await this.$services.coaching.getSummonerRankDetailInfo(summonerName, silent);
      if (!summonerInfo?.profile) this.hasRiotError = true;
      else this.hasRiotError = false;
      return summonerInfo;
    },
    async recommendationBtnHandler() {
      try {
        const summonerInfo = await this.getSummonerRankDetailInfo(this.summonerName);
        this.summonerInfo = summonerInfo;
      } catch (e) {
        this.hasSummonerNameError = true;
        return;
      }
      if (this.isHigherTier) {
        this.questionStep = 1;
        const el = this.$refs?.questions?.$refs?.step1;
        el?.setHigherTierTextFocus();
      }

      this.$gtag.event('click_coach_recommend_start_btn', {
        event_category: 'coach_recommend',
        event_label: 'click_coach_recommend_btn',
        value: this.summonerName,
      });

      this.goQuestionSection();
    },
    goQuestionSection() {
      this.setPrevSurveyIntoStorage();
      this.section = 'question';
    },
    nextQuestionStep() {
      this.questionStep += 1;
      this.setPrevSurveyIntoStorage();
    },
    prevQuestionStep() {
      if (this.questionStep === 1) {
        this.section = 'main';
        this.clearPrevSurveyStorage();
        return;
      }
      if (this.questionStep <= 3 && !this.isHigherTier && !this.noSummoner) {
        this.section = 'main';
        this.clearPrevSurveyStorage();
        return;
      }
      this.questionStep -= 1;
      this.setPrevSurveyIntoStorage();
    },
    directToMainSection() {
      this.section = 'main';
      this.questionStep = 1;
      this.recommendationResult = {};
    },
    async answerHandler(answers) {
      this.answers = answers;
      try {
        this.recommendationResult = await this.$services.coaching.requestRecommendationCoach({ answers, summonerRankInfo: this._summonerInfo, isHigherTier: this.isHigherTier, hasRiotError: this.hasRiotError });

        if (!this.isLogin) {
          const expiredDatetime = this.recommendationResult?.expiredDatetime || 0;
          const recommendationCoachSurveyId = this.recommendationResult?.recommendationCoachSurveyId || '';
          getPersistentStorage('coach-recommend').set('surveyId', { recommendationCoachSurveyId, expiredDatetime });
        }
        // 코치 추천을 받았는지 여부를 설정
        this.section = 'result';
        this.clearPrevSurveyStorage();
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    const st = getPersistentStorage('coach-recommend');

    let surveyObj = st.get('surveyId');
    if (surveyObj) {
      const { expiredDatetime } = surveyObj;
      const current = new Date().valueOf();
      if (current > expiredDatetime) {
        st.remove('surveyId');
        surveyObj = null;
      }
    }

    const prevStep = st.get('step');
    const prevAnswers = st.get('answers');
    const prevSummonerName = st.get('nickname');

    if (this.isLogin) {
      if (surveyObj) {
        const { recommendationCoachSurveyId } = surveyObj;
        this.recommendationResult = await this.$services.coaching.setMyRecommendationCoachesWithSurveyId(recommendationCoachSurveyId);
        st.remove('surveyId');
      }
    } else if (surveyObj) {
      const { recommendationCoachSurveyId } = surveyObj;
      this.recommendationResult = await this.$services.coaching.getMyRecommendationCoachesWithSurveyId(recommendationCoachSurveyId);
    }

    if (!prevStep && this.recommendationResult?.survey) {
      const splittedVersion = this.recommendationResult?.survey?.version?.split('_');
      const isHigherTier = splittedVersion?.[splittedVersion.length - 2] === 'HIGH' && splittedVersion?.[splittedVersion.length - 1] === 'TIER';
      this.section = 'result';
      this.answers = this.recommendationResult?.survey?.qna || [];
      this.summonerInfo = this.recommendationResult?.survey?.soloRankInfo || {};
      this.summonerName = this.summonerInfo?.profile?.summonerName || '';
      this.hasRiotError = !!(!this.summonerInfo?.profile && !splittedVersion[2]);
      this.questionStep = isHigherTier || this.noSummoner ? 5 : 7;
    } else if (prevStep) {
      try {
        if (prevSummonerName) this.summonerInfo = await this.getSummonerRankDetailInfo(prevSummonerName, false);
        else {
          // noSummoner 분기
          this.summonerInfo = this.makeNoSummonerInfo();
        }
      } catch (e) {
        this.section = 'main';
        return;
      }
      this.summonerName = prevSummonerName || this.summonerInfo?.profile?.summonerName || '';
      this.questionStep = prevStep;
      this.answers = prevAnswers;
      this.section = 'question';
    }

    if (!prevStep && !this.recommendationResult?.survey) this.section = 'main';

    this.$gtag.event('land_coach_recommend', {
      event_category: 'coach_recommend',
      event_label: 'land_coach_recommend',
    });
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  async asyncData({ services, store }) {
    let recommendationResult = {};
    const section = 'init';
    let questionStep = 1;

    if (store.state.auth?.myInfo) {
      try {
        recommendationResult = await services.coaching.getMyRecommendationCoaches();
      } catch ({ code }) {
        return;
      }
    }

    if (recommendationResult?.survey) {
      const splittedVersion = recommendationResult?.survey?.version?.split('_');
      const isHigherTier = splittedVersion?.[splittedVersion.length - 2] === 'HIGH' && splittedVersion?.[splittedVersion.length - 1] === 'TIER';
      questionStep = isHigherTier ? 5 : 7;
    }

    const answers = recommendationResult?.survey?.qna || [];
    const soloRankInfo = recommendationResult?.survey?.soloRankInfo || {};
    const summonerName = soloRankInfo?.profile?.summonerName || '';
    return { recommendationResult, section, questionStep, answers, summonerInfo: soloRankInfo, summonerName };
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-recommend] { .bgc(#f6f6f6);
  &.bgc-w { .bgc(#fff);}
  .inner-wrap {.flex; .justify-center; .min-h(inherit); align-items: center; .p(40); }
  .result-inner-wrap {.max-w(1280); .m(0, auto); .pt(100);}
  .searching-wrap, .not-found-wrap { .flex; .items-center; .justify-center; .w(100%); .h(100%); .bgc(#fff); .min-h(calc(100vh - 68px));
    .holder { text-align: center;
      > div {.flex; .justify-center; .items-center;}
      .text {.fs(24); .lh(34);
        > em {.bold;}
      }
      img {.m(0, 9); .w(28); .h(28);}
      .loading {.wh(284); .mt(-50); .ml(auto); .mr(auto);}
    }

    .go-main-btn {.h(64); .w(416); text-align: center; .m(60, auto);
      p {.mr(4); .flex; .items-center; .fs(18);
        .emoji {.mr(4); .wh(20); }
        .arrow-dark {transform: rotate(90deg); .wh(18);}
        .arrow-white {transform: rotate(-90deg); .wh(18);}
      }
    }
  }
  .page-border {.br(4); box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08); }
  .section-wrap { .w(914); .max-w(914); .min-h(600); .flex; .justify-center; .bgc(#fff);
    .content { .w(100%); .pt(90);
      .inner {
        &.main {.pt(26); .max-w(416); .m(0, auto); }
        &.question {.pt(94); .p(0, 93); }
        .btn-holder { .mt(40); .flex; gap: 16px;
          [basic-button] {.fs(14); .c(#777); .lh(100%); font-weight: 500; .p(0); width: 100%; .h(40);
            &:hover {.c(#191919); }
          }
        }
        .desc-wrap {
          p {.c(#000); }
          p:nth-of-type(1) {.bold; .flex; align-items: center; justify-content: center; .fs(20); .lh(32); text-align: center;
            img { .mr(4); .wh(28);}
          }
          p:nth-of-type(2) { .fs(16); .lh(32); text-align: center; }
        }
        .input-wrap {
          .error-text {.c(#f00); .fs(14); .lh(20); .mt(8); }
          .search-input {.block; .mt(48); .w(100%); .h(64); .br(4);
            .search-icon {.t(16); .wh(32); }
            [text-input] {.flex; .items-center; }
            input {.fs(18); .pl(50);}
            .cancel-btn {.t(20px);}
            .warn-icon {.t(20px); }
          }
          .recommendation-btn {.mt(16); .w(100%); .h(64); .fs(18); .br(4);}
        }
      }
      .divider {.mt(48); .h(1); border: 1px solid #ebebeb;}
    }
  }
  .error-text-wrap {.h(6);}

  @media (@tl-down) {
    .result-inner-wrap { .pr(58); .pl(58);}
  }

  @media (@ml-down) {
    .bgc(#fff);
    .page-border {box-shadow: unset;}
    .inner-wrap {.p(20);}
    .section-wrap > .content > .inner.main {.max-w(unset); .pt(0);}
    .section-wrap {
      .content { .pt(60);
        .inner {
          &.question {.p(0);}
          .btn-holder {
            flex-wrap: wrap;
            [basic-button] {.fs(16); .h(48);}
          }
          .desc-wrap {
            p:nth-of-type(2) {.mt(8);}
          }
        }
      }
    }
    .result-inner-wrap { .pr(20); .pl(20); .pt(60);}
    .searching-wrap, .not-found-wrap { .p(0, 20);
      .holder {
        .text {.fs(16); .lh(32);
          > em {.fs(20);}
        }
      }
      .go-main-btn {.w(100%); .p(0); .mt(40);}
    }
  }
}
</style>
