<template>
  <div :class="{focus: focus, error: hasError }" underline-text-input>
    <slot></slot>
    <input ref="input" v-if="!useContent" v-model="v" :placeholder="placeholder" @blur="focus = false" @focus="focus = true" @input="input" @keydown.enter="$emit('enter')">
    <svg-warn v-show="hasError" alt="icon-warn" class="warn-icon" />
    <button v-show="v && !useContent" class="clear-btn" @click="clearBtnHandler">
      <svg-cancel alt="icon-cancel" class="cancel-icon" />
    </button>
  </div>
</template>

<script>
import SvgWarn from '@/views/graphics/svg-warn.vue';
import SvgCancel from '@/views/graphics/svg-cancel.vue';

export default {
  name: 'UnderlineTextInput',
  components: { SvgWarn, SvgCancel },
  props: {
    value: {
      default: '',
    },
    hasError: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    useContent: { type: Boolean, default: false },
  },
  watch: {
    value(v) {
      this.v = v;
    },
  },
  data: () => ({
    focus: false,
    v: '',
  }),
  methods: {
    clearBtnHandler() {
      this.v = '';
      this.$emit('input', this.v);
    },
    input(e) {
      const { value } = e.target;
      this.$emit('input', value);
    },
    setFocus() {
      this.$refs?.input?.focus();
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[underline-text-input] { .flex; .items-center; .h(46); border-bottom: 2px solid #ddd; .p(0, 12);
  &.focus {border-bottom: 2px solid #191919;}
  &.error {border-bottom: 2px solid #f00 !important;}
  > input {.w(100%); .fs(14); .c(#191919); .lh(14); border: none; }
  .clear-btn { .flex;
    .cancel-icon {.wh(24); .ml(6); }
  }
}

</style>
