<template>
  <div :class="{hover: hover}" coach-card-preview @click="clickHandler"
    @mouseenter="() => (hover = !hoverDisabled && true)" @mouseleave="() => (hover =  false)">
    <div class="bg-no-drape" v-if="showRecommendInfo" />
    <CoachCardDrape :fillColor="hover ? '#596AFF': '#f2f2fb'" class="preview-drape" v-else />
    <FlagLabel v-if="isAdvertising && !showRecommendInfo" class="flag-label" label="AD" />
    <MedalLabel v-if="!isAdvertising && rank && !showRecommendInfo" :color="medalColor" :label="rank" class="medal-label" />
    <div class="preview-profile-img-wrap">
      <Avatar :info="coachInfo.user" :defaultImgType="'fruit'" class="profile-avatar" dimension="200x200" userProfile />
      <div :class="{online: isOnline, coaching: isCoaching}" class="profile-online"></div>
    </div>
    <div class="preview-content-wrap">
      <h1 class="preview-content-nickname">{{ nickname }}</h1>
      <div v-if="!showRecommendInfo" class="preview-stats">
        <div v-if="isOfficial" class="official-badge">{{ $t('coaching.MS_tag_official_S') }}</div>
        <cdnImg v-if="showRankIcon" :src="lolTierImgUrl" class="tier-rank" />
        <img v-else class="official-emblem" src="/img/icon/icon-emblem-official.svg" />
        <dl class="stat-item rating">
          <dd>{{ reviewRating }}</dd>
          <dt>별점</dt>
        </dl>
        <dl class="stat-item count">
          <dd>{{ reviewCount }}</dd>
          <dt>리뷰</dt>
        </dl>
      </div>
      <div v-else class="preview-stats recommend">
        <dl class="stat-item rating">
          <dd>{{ reviewRating }}</dd>
          <dt>별점</dt>
        </dl>
        <dl class="stat-item rating">
          <dd>{{ lessonCount }}</dd>
          <dt>수업</dt>
        </dl>
        <dl class="stat-item rating">
          <dd>{{ reviewCount }}</dd>
          <dt>후기</dt>
        </dl>
        <dl class="stat-item count">
          <dd>{{ bookmarkCount }}</dd>
          <dt>찜</dt>
        </dl>
      </div>
      <div class="recommend-mode-bottom" v-if="showRecommendInfo">
        <div class="tags">
          <tag :class="{'hot': tag.priority === 1}" v-for="(tag, index) in tags" :key="index">{{ tag.name }}</tag>
        </div>
        <p class="best-review">
          <template v-if="bestReview === ''">아직 리뷰가 없는 신규 코치님입니다.</template>
          <template v-else>"{{ bestReview }}"</template>
        </p>
      </div>
      <ul class="preview-history" v-else>
        <li v-for="(history, i) in mainHistory" :key="i">
          {{ history }}
        </li>
      </ul>
      <div class="preview-more-view">
        <label class="more-view-text">{{ $t('coaching.BA_main_moreview') }}</label>
        <button class="more-view-btn">
          <img src="/img/icon/icon-arrow-right-white.svg" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@shared/components/common/Avatar.vue';
import CdnImg from '@shared/components/common/CdnImg.vue';
import CoachCardDrape from '@/views/components/coaching/CoachCardDrape.vue';
import FlagLabel from '@/views/components/coaching/FlagLabel.vue';
import MedalLabel from '@/views/components/coaching/MedalLabel.vue';
import Tag from '@/views/components/coaching/Tag.vue';

export default {
  name: 'CoachCardPreview',
  components: {
    FlagLabel, MedalLabel, Avatar, CoachCardDrape, CdnImg, Tag,
  },
  props: {
    coachInfo: {
      default: () => ({}),
    },
    rank: {
      type: Number,
      default: 0,
    },
    // 현재 코치 추천 페이지인지 여부
    showRecommendInfo: {
      type: Boolean,
      default: false,
    },
    hoverDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hover: false,
  }),
  computed: {
    _coachInfo() {
      return /** @type {Coach} */ this.coachInfo;
    },
    nickname() {
      return this?._coachInfo?.user?.nickname;
    },
    reviewRating() {
      return this?._coachInfo?.reviewRating || '-';
    },
    reviewCount() {
      return this?._coachInfo?.reviewCount || '-';
    },
    lolTierImgUrl() {
      return this?._coachInfo?.rank?.logoUrl;
    },
    showRankIcon() {
      return !!this?._coachInfo?.rank?.logoUrl && !this.isOfficial;
    },
    isOfficial() {
      return this?._coachInfo?.tags?.includes('OFFICIAL');
    },
    mainHistory() {
      return this?._coachInfo?.mainHistory;
    },
    bestReviews() {
      return /** @type{Pick<LessonReview, 'description'>[]} */this?._coachInfo.bestReview ?? [];
    },
    bestReview() {
      return this.bestReviews?.[0]?.description ?? '';
    },
    tags() {
      return /** @type{CoachTagAlt[]} */ this._coachInfo?.tags ?? [];
    },
    isAdvertising() {
      return this?._coachInfo?.isAdvertising;
    },
    medalColor() {
      return this.rank < 4 ? 'pink' : 'purple';
    },
    isOnline() {
      return this?._coachInfo?.status === 'ONLINE';
    },
    isCoaching() {
      return this?._coachInfo?.status === 'COACHING';
    },
    lessonCount() {
      const count = this?._coachInfo?.lessonCount;
      return count > 100 ? '100+' : this?._coachInfo?.lessonCount || '-';
    },
    bookmarkCount() {
      return this?._coachInfo?.bookmarkCount || '-';
    },
  },
  methods: {
    clickHandler() {
      this.$emit('examine', this._coachInfo?.coachId);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
@card-height: 390;

[coach-card-preview] { .rel; .mt(50); .w(308); .h(@card-height); border: 1px solid #ebebf0; .br(20); .bgc(#fff); transition: 0.2s; cursor: pointer;
  .flag-label { .abs; .t(-1); .l(20); z-index: 1; }
  .medal-label { .abs; .t(-1); .l(20); z-index: 1; }
  .preview-profile-img-wrap { .abs; .t(-50); .l(104);
    .profile-avatar { .ib; .w(92); .h(92); border: 4px solid #fff; filter: drop-shadow(0px 12px 16px rgba(0, 0, 0, 0.1)); }
    .profile-online { .abs; .r(6); .b(10); .w(14); .h(14); .br(100%); border: 2px solid #fff; .bgc(#5f5f5f);
      &.online { .bgc(#0ce757); }
      &.coaching { .bgc(#6aa6ff); }
    }
  }
  .preview-content-wrap { .abs; .t(70); .l(0); .r(0); .b(0); .br(20);
    .preview-content-nickname { .block; .tc; .m(0); .fs(20); .bold; .c(#000); .h(29); .lh(22); word-break: break-all;}
  }
  .preview-stats { .flex; justify-content: space-between; .items-center; .mt(18); .ml(46); .w(216);
    .tier-rank { .w(42); .h(42); .br(12); .mr(18); .bgc(#fff); }
    .stat-item {.w(100%);
      dd { .tc; .fs(16); .bold; .mb(3); .c(#2c81ff); }
      dt { .tc; .fs(12); .regular; .c(#777); }

      &.rating { .mr(18); }
    }
    .official-emblem { .w(42); .h(42); .bgc(#fff); .br(12); .mr(18); }
    .official-badge { .bg('/img/coaching/official-badge-shape.svg'); .w(42); .h(42); .c(#fff); .fs(12); .tc; .pt(11); .mr(18); }
  }
  .bg-no-drape {.bgc(#f2f2fb);.h(182);.w(100%);border-top-right-radius: 20px;border-top-left-radius: 20px;}
  .recommend-mode-bottom { .mt(42); .ml(20); .mr(20); .mb(8);.h(158);overflow: hidden;}
  .best-review {.fs(14); .lh(26);.c(#777);.pt(8);margin-block-start: 0;margin-block-end: 0;word-break: break-all;}
  .tags { .w(270);
    [tag] { .bgc(#fff); border: 1px solid #ebebeb;.m(0);.mr(8);.mb(8); }
    .hot { .c(#658df2); .bgc(#f7f9ff); border: 1px solid #c6d3ff; }
  }
  .preview-history { .h(78); .fs(14); .lh(26); .mt(64); .ml(20); .mr(20); .mb(12); overflow-y: hidden;
    li { .rel; .c(#2c71ff); .ml(12); text-overflow: ellipsis;
      &:after { .cnt(''); .abs; .br(100%); .w(4); .h(4); .t(11); .l(-10); .bgc(#a4a3ae); }
    }
  }
  .preview-more-view { .flex; .justify-end; .items-center; .o(0); cursor: pointer; .mr(20);
    .more-view-text { .c(#fff); .mr(8); .fs(14); cursor: pointer; }
    .more-view-btn { .bgc(#f19638); .br(none); .w(48); .h(48); .br(8); box-shadow: 0px 16px 24px rgb(0 0 0 / 30%); cursor: pointer; }
  }

  &.hover { .h(381); .bgc(#5555e2);
    .preview-content-wrap {
      .preview-content-nickname { .c(#fff); }
      .preview-stats {
        .stat-item {
          dd { .c(#fff); }
          dt { .c(#c1c7ff); }
        }
      }
    }
    .preview-history {
      li { .c(#fff);
        &:after { .bgc(#fff); }
      }
    }
    .preview-more-view { .o(1); }
  }
}
</style>
