<template>
  <svg :style="styleSvg" coach-card-drape viewBox="0 0 308 333" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" height="333" mask-type="alpha" maskUnits="userSpaceOnUse" width="308" x="0" y="0">
      <rect height="333" width="308" x="0" y="0" />
    </mask>
    <g mask="url(#mask0)">
      <circle cx="154" cy="0" r="207" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CoachCardDrape',
  props: {
    fillColor: {
      type: String,
      default: '#f2f2fb',
    },
  },
  computed: {
    styleSvg() {
      return { fill: this.fillColor };
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[coach-card-drape] { transition: 0.3s fill; border-radius: 20px}
</style>
