<template>
  <div coach-tv-playlist-card @click="clickHandler" :data-theme="theme">
    <div class="thumbnail">
      <CdnImg :src="thumbnailUrl" class="thumbnail-img" />
    </div>
    <div class="info-wrap">
      <p class="info-title">
        {{ title }}
      </p>
      <span class="info-date">
        {{ date }}
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import CdnImg from '@shared/components/common/CdnImg.vue';

export default {
  name: 'CoachTvPlaylistCard',
  components: {
    CdnImg,
  },
  props: {
    thumbnailUrl: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dateTime: {
      type: Number,
      default: 0,
    },
    theme: {
      type: String,
      default: 'base',
    },
  },
  computed: {
    date() {
      return moment(this.dateTime).format('YYYY.MM.DD');
    },
  },
  methods: {
    clickHandler() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[coach-tv-playlist-card] { .w(100%); .pointer();
  .thumbnail { .w(100%); .h(188px); .mh-c;
    .thumbnail-img { .w(100%); .h(inherit); border: none; object-fit: cover; object-position: center; .br(20); }
  }

  @media (@tp-up) {
    .thumbnail { .h(164px);}
  }

  @media (@ds-up) {
    .thumbnail { .h(173px);}
  }

  .info-wrap { .mt(18px); .h(66); .rel;
    .info-title {.fs(16px); line-height: 21px; color: #191919; .bold(); .ellipsis(2); .m(0) }
    .info-date { .block(); .abs; .b(0); .l(0); .mt(6); .medium(); .fs(12px); .c(#a4a3ae);}
  }

  &[data-theme='coach-detail'] {
    .thumbnail .thumbnail-img { .br(4); }
  }
}
</style>
