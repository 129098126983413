<template>
  <section recommend-question-step-3>
    <ChooseABList v-bind="{answerList, list:questionList}" @answer="answerHandler" />
    <div class="btn-wrap">
      <BasicButton theme="grey" @click="$emit('prevBtn')">
        <p>
          <img class="arrow-dark" src="/img/icon/icon-arrow-down-dark.svg" />
          이전
        </p>
      </BasicButton>
      <BasicButton v-if="showNextBtn" theme="dark" @click="$emit('nextBtn')" class="w-84 next">
        <p>다음
          <img class="arrow-white" src="/img/icon/icon-arrow-down-white.svg" />
        </p>
      </BasicButton>
    </div>
  </section>
</template>

<script>
import ChooseABList from '@/views/components/common/input/ChooseABList.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';

export default {
  name: 'RecommendQuestionStep3',
  components: { ChooseABList, BasicButton },
  props: {
    step: {
      type: Number, default: 0,
    },
  },
  data: () => ({
    questionList: [
      { textA: '“씐나 씐나”\n(재미있고 텐션높은)', textB: '“오늘 배울 내용은”\n(진지하고 차분한)' },
      { textA: '멘탈 케어가 필요해요. 다정하게 알려주세요!', textB: '팩트 위주로 때려주세요' },
      { textA: '뇌지컬 강화 (이론 중심)', textB: '피지컬 강화 (실전 중심)' },
    ],
    answerList: [],
  }),
  computed: {
    showNextBtn() {
      return this.answerList.length === this.questionList.length;
    },
  },
  methods: {
    answerHandler({ answer, item, index }) {
      const { answerList } = this;
      if (answerList?.[index]) {
        const temp = [...answerList];
        temp[index] = { answer, item };
        this.answerList = temp;
        return;
      }
      answerList.push({ answer, item });
    },
    pushAnswer(step) {
      const { questionList } = this;
      const answers = this.answerList.map((answer, i) => {
        const value = answer?.answer === 'A' ? (i + i + 1) : (i + i + 2);
        const text = answer?.answer === 'A' ? questionList?.[i]?.textA : questionList?.[i]?.textB;
        return { number: value, value, text };
      });

      const answer = { number: step, question: '', answers };
      this.$emit('answer', { step, answer });
    },
    setAnswer(_answer) {
      this.answerList = [];
      const { answers } = _answer;
      for (let i = 0; i < answers?.length; i++) {
        const _answer = Number(answers[i]?.value) === (i + i + 1) ? 'A' : 'B';
        this.answerList.push({ answer: _answer });
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[recommend-question-step-3] {
  .btn-wrap {.abs; .b(40); .w(100%); .flex; .space-between;
    > [basic-button] { .h(40); .w(80);
      &.w-84 {.w(84);}
      p {.mr(4); .flex; .items-center; .fs(14);
        .emoji {.mr(4); }
        .arrow-dark {transform: rotate(90deg); .wh(18);}
        .arrow-white {transform: rotate(-90deg); .wh(18);}
      }
    }
  }

  @media (@ml-down) {
    .btn-wrap { justify-content: unset;
      > [basic-button] {
        &.next {.ml(8);}
        p {.fs(14);}
      }
    }
  }

}

</style>
