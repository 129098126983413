<template>
  <section recommend-question-step-1>
    <div class="summoner-wrap">
      <div class="riot-error" v-if="hasRiotError">
        <p>라이엇 공식 API 이슈로 인하여<br />소환사에 대한 전적 데이터를 불러올 수 없습니다.</p>
      </div>
      <h4 class="profile-text1">당신의 현재 티어는</h4>
      <h4 class="profile-text3">당신의 현재 티어입니다.</h4>
      <LolTierInfo :disabled="hasRiotError" v-bind="summonerRankProfile" />
      <h4 class="profile-text2">입니다.</h4>
    </div>
    <div v-if="isHigherTier" class="higher-tier">
      <div class="higher-tier-text">
        <img src="/img/icon/icon-firecracker.svg" />
        <p>대단해요! 고티어 이시군요! 배우고 싶은 내용을 직접 적어주시면 코치님께 전달드릴게요!</p>
      </div>
      <ValidationProvider v-slot="{errors}" name="higherTierText" rules="lengthMin:0|lengthMax:100">
        <MemoTextArea ref="highTierMemo" v-model="_higherTierText" :hasError="!!errors.length" :maxLength="100" :minLength="1" placeholder="" />
      </ValidationProvider>
    </div>
    <ul v-else class="question-order-wrap">
      <li>
        <div :class="{'inactive': step !== 1}" class="left">
          <h4 v-if="isShowQuestionNumber(1)" :class="{active: step === 1, 'inactive': step !== 1}" class="order-number">1</h4>
          <img v-else src="/img/icon/coaching/icon-check-grey.svg" />
        </div>
        <div class="right">
          <div :class="{closed: openedQuestionNumber !== 1}" class="lane-champ-select-wrap">
            <p class="sub-text">(최근 20게임 데이터)</p>
            <div class="lane-infos">
              <LolLaneInfo v-for="(laneInfo, i) in summonerLaneInfos" :key="i" :selected="selectedLane === laneInfo.lane" v-bind="laneInfo" @click="laneSelectHandler" />
            </div>
            <div class="selected-lane" @click="setOpenedQuestionNumber(1)">
              <img :src="selectedLaneIcon" class="position-icon" />
              <p>포지션과</p>
            </div>
            <div class="champ-select-wrap">
              <WinRateChampSelector v-model="_selectedChampCode" :champs="summonerChampInfos" @selectChamp="selectChampHandler" />
              <p>을(를) 배우고 싶어요!</p>
            </div>
            <p>을(를) 배우고 싶어요!</p>
          </div>
        </div>
      </li>
      <li>
        <div :class="{'inactive': step !== 2}" class="left">
          <h4 v-if="isShowQuestionNumber(2)" :class="{active: step === 2, 'inactive': step !== 2}" class="order-number">2</h4>
          <img v-else src="/img/icon/coaching/icon-check-grey.svg" />
        </div>
        <div class="right">
          <div :class="{closed: openedQuestionNumber !== 2}" class="tier-select-wrap">
            <div :class="{disabled: !answeredQuestionNum.includes(1)}" class="goal-tier" @click="setOpenedQuestionNumber(2)">
              <p>목표 티어는</p>
              <div class="tier-holder">
                <img :src="`/img/lol/emblem-${goalTier}.svg`" />
              </div>
              <p>이상입니다.</p>
            </div>
            <GoalTierSelector v-model="_goalTier" />
          </div>
        </div>
      </li>
      <li>
        <div :class="{'inactive': step !== 3}" class="left">
          <h4 v-if="!selectedItemIndex.length" :class="{active: step === 3, 'inactive': step !== 3}" class="order-number">3</h4>
          <img v-else src="/img/icon/coaching/icon-check-grey.svg" />
        </div>
        <div class="right">
          <div :class="{closed: openedQuestionNumber !== 3}" class="question-select-wrap">
            <div :class="{disabled:!answeredQuestionNum.includes(2) }" class="question-select-text" @click="setOpenedQuestionNumber(3)">
              <p>그리고</p>
              <span>(중복 선택 가능)</span>
            </div>
            <div class="questions">
              <div v-for="(item, i) in questionItems" :key="i" class="question-item">
                <BasicButton :selected="selectedItemIndex.includes(i)" @click="itemClickHandler(i, item)">
                  <pre>{{ i === 1 && selectedLane === 'JUNGLE' ? formatQuestionText('[{lane}] 동선 및 운영방식을 배우고 싶어요') : formatQuestionText(isMobile ? item.mobileText || item.text : item.text) }}</pre>
                </BasicButton>
                <div v-if="item.type === 'input' && selectedItemIndex.includes(i)" class="friends-summoner-wrap">
                  <UnderlineTextInput ref="nicknameInput" v-model="friendsSummonerNickname" :hasError="hasSummonerNameError" :placeholder="isMobile ? '친구 소환사명 입력':'친구의 소환사명을 입력해주세요.'" :useContent="!!friendsSummoner" class="friends-summoner" @enter="friendsSummonerBtnHandler">
                    <div v-if="!!friendsSummoner" class="friends-summoner-info">
                      <div class="tier-img-wrap">
                        <img :src="`/img/lol/emblem-${friendsSummonerTier}.svg`" />
                      </div>
                      <p>{{ friendsSummonerNickname }}</p>
                    </div>
                  </UnderlineTextInput>
                  <BasicButton theme="dark" @click="friendsSummonerBtnHandler">{{ !!friendsSummoner ? '재입력' : '확인' }}</BasicButton>
                </div>
                <ValidationProvider v-slot="{errors}" name="answerText" rules="lengthMin:0|lengthMax:100">
                  <MemoTextArea v-if="item.type === 'text' && selectedItemIndex.includes(i)" ref="answerInput" v-model="_answerText" :hasError="!!errors.length" :maxLength="100" :minLength="0" :placeholder="''" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="btn-wrap">
      <BasicButton theme="grey" @click="$emit('prevBtn')">
        <p>
          <img class="arrow-dark" src="/img/icon/icon-arrow-down-dark.svg" />
          이전
        </p>
      </BasicButton>
      <BasicButton class="w-84 next" v-if="showNextBtn" theme="dark" @click="$emit('nextBtn')">
        <p>다음
          <img class="arrow-white" src="/img/icon/icon-arrow-down-white.svg" />
        </p>
      </BasicButton>
    </div>
  </section>
</template>

<script>
import LolTierInfo from '@/views/components/coaching/lol/LolTierInfo.vue';
import LolLaneInfo from '@/views/components/coaching/lol/LolLaneInfo.vue';
import WinRateChampSelector from '@/views/components/coaching/lol/WinRateChampSelector.vue';
import GoalTierSelector from '@/views/components/coaching/lol/GoalTierSelector.vue';
import { LOL_POSITION_ICON } from '@/constants/lol';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import UnderlineTextInput from '@/views/components/common/input/UnderlineTextInput.vue';
import MemoTextArea from '@/views/components/common/input/MemoTextArea.vue';

export default {
  name: 'RecommendQuestionStep1',
  components: { MemoTextArea, UnderlineTextInput, BasicButton, WinRateChampSelector, LolLaneInfo, LolTierInfo, GoalTierSelector },
  data: () => ({
    selectedLane: '',
    selectedChampName: '',
    selectedChampCode: '',
    goalTier: 'UNRANKED',
    friendsSummonerNickname: '',
    friendsSummoner: null,
    hasSummonerNameError: false,
    answerText: '',
    hasAnswerTextError: false,
    openedQuestionNumber: 1,
    answeredQuestionNum: [],
    selectedItemIndex: [],
    higherTierText: '',
    hasHigherTierTextError: false,
  }),
  watch: {
    friendsSummonerNickname(v) {
      if (!v) {
        this.hasSummonerNameError = false;
      }
    },
    isHigherTier(val, oldVal) {
      if (oldVal === true) {
        // this.answeredQuestionNum = [];
        this.openedQuestionNumber = 1;
      }
      if (!val && oldVal && this.answeredQuestionNum.length) {
        this.openedQuestionNumber = this.answeredQuestionNum.length;
        this.$emit('setStep', this.answeredQuestionNum.length);
      }
    },
  },
  props: {
    step: {
      type: Number, default: 0,
    },
    summonerRankProfile: {
      default: () => ({}),
    },
    summonerLaneInfos: {
      default: () => ([]),
    },
    summonerChampInfos: {
      default: () => ([]),
    },
    isHigherTier: {
      type: Boolean, default: false,
    },
    hasRiotError: {
      type: Boolean, default: false,
    },
  },
  computed: {
    isMobile() {
      return this.matchedMediaDevice === 'M';
    },
    friendsSummonerTier() {
      const tier = String(this.friendsSummoner?.tier).split('_')?.[0];
      return tier;
    },
    showNextBtn() {
      if (this.isHigherTier) {
        return this.higherTierText?.length && !this.hasHigherTierTextError;
      }
        return this.selectedLane && this.selectedChampName && this.goalTier !== 'UNRANKED' && !this.hasAnswerTextError && !this.hasSummonerNameError && this.selectedItemIndex.length;
    },
    questionItems() {
      return [
        { text: '[{champ}]의 상황별 룬, 아이템을 배우고 싶어요', type: 'button' },
        { text: '[{lane}] 라인전 이후의 운영방식을 배우고 싶어요', type: 'button' },
        { text: '한타 포지셔닝과 피지컬을 키우고 싶어요', type: 'button' },
        { mobileText: '오랜만에 다시 시작하려고 하니 막막하네요\n최신 패치 내용을 배우고 싶어요', text: '오랜만에 다시 시작하려고 하니 막막하네요. 최신 패치 내용을 배우고 싶어요', type: 'button' },
        { text: '내 친구보다 잘하고 싶어요', type: 'input' },
        { text: '나만의 목표가 있어요', type: 'text' }];
    },
    _selectedChampCode: {
      get() {
        return this.selectedChampCode;
      },
      set(v) {
        this.selectedChampCode = v;
      },
    },
    _goalTier: {
      get() {
        return this.goalTier;
      },
      set(v) {
        this.goalTier = v;
        this.setAnsweredQuestion(2);
      },
    },
    _answerText: {
      get() {
        return this.answerText;
      },
      set(v) {
        this.hasAnswerTextError = false;
        if (v?.length > 100) this.hasAnswerTextError = true;
        this.answerText = v;
      },
    },
    _higherTierText: {
      get() {
        return this.higherTierText;
      },
      set(v) {
        if (v?.length > 100 || v?.length < 1) this.hasHigherTierTextError = true;
        else {
          this.hasHigherTierTextError = false;
        }
        this.higherTierText = v;
      },
    },
    selectedLaneIcon() {
      return LOL_POSITION_ICON?.[this.selectedLane];
    },
  },
  methods: {
    laneSelectHandler(lane) {
      this.selectedLane = lane;
      this.setOpenedQuestionNumber(this.step);
      if (this.selectedChampName) this.setAnsweredQuestion(1);
    },
    selectChampHandler(champ) {
      this.selectedChampName = champ?.koName;
      if (this.selectedLane) this.setAnsweredQuestion(1);
    },
    setAnsweredQuestion(num) {
      if (!this.answeredQuestionNum.includes(num)) {
        this.answeredQuestionNum.push(num);

        if (this.step < 3) {
          this.setOpenedQuestionNumber(num + 1);
          this.$emit('nextStep');
        }
        return;
      }
      this.pushAnswer(num);
      this.setOpenedQuestionNumber(this.step);
    },
    setOpenedQuestionNumber(num) {
      this.openedQuestionNumber = num;
    },
    isQuestionAnswered(num) {
      return this.answeredQuestionNum.includes(num);
    },
    isShowQuestionNumber(num) {
      return !this.answeredQuestionNum.includes(num);
    },
    formatQuestionText(text) {
      return text?.replace('{champ}', this.selectedChampName)?.replace('{lane}', this.$t(`coaching.MC_lol_position_${this.selectedLane?.toLowerCase()}`));
    },
    async friendsSummonerBtnHandler() {
      if (!this.friendsSummonerNickname) return;
      if (this.friendsSummoner) {
        this.friendsSummoner = null;
        this.friendsSummonerNickname = '';
        return;
      }
      try {
        const summoner = await this.$services.coaching.getSummonerRankInfo(this.friendsSummonerNickname);
        this.friendsSummoner = summoner;
        this.hasSummonerNameError = false;
      } catch (e) {
        this.hasSummonerNameError = true;
      }
    },
    itemClickHandler(i, item) {
      const idx = this.selectedItemIndex.indexOf(i);
      if (idx > -1) {
        this.$delete(this.selectedItemIndex, idx);
        if (item?.type === 'input') {
          this.friendsSummoner = null;
          this.friendsSummonerNickname = '';
          this.hasSummonerNameError = false;
        }
        if (item?.type === 'text') {
          this.answerText = '';
          this.hasAnswerTextError = false;
        }
      } else {
        this.selectedItemIndex.push(i);

        if (item?.type === 'input') {
          this.$nextTick(() => {
            this.$refs?.nicknameInput?.[0]?.setFocus();
          });
        }
        if (item?.type === 'text') {
          this.$nextTick(() => {
            this.$refs?.answerInput?.[0]?.onTipClick();
          });
        }
      }
      this.setAnsweredQuestion(3);
    },
    getTierStr(tier) {
      const tierList = ['BRONZE', 'SILVER', 'GOLD', 'PLATINUM', 'DIAMOND', 'MASTER', 'GRANDMASTER', 'CHALLENGER'];
      const tierStrKey = ['LOL_TIER_BRONZE_0', 'LOL_TIER_SILVER_0', 'LOL_TIER_GOLD_0', 'LOL_TIER_PLATINUM_0', 'LOL_TIER_DIAMOND_0', 'LOL_TIER_MASTER', 'LOL_TIER_GRANDMASTER', 'LOL_TIER_CHALLENGER'];
      const tierIdx = tierList.indexOf(tier);
      return this.$t(`coaching.${tierStrKey[tierIdx]}`);
    },
    pushAnswer(step) {
      let answer = { number: step, question: '', answers: [] };
      if (step === 1) {
        if (this.isHigherTier) {
          answer = {
            number: step,
            question: '',
            answers: [{ number: 1, value: this.higherTierText, text: '' }],
          };
        } else if (!this.isHigherTier) {
            const laneText = this.$t(`coaching.MC_lol_position_${this.selectedLane.toLowerCase()}`);
            answer = {
              number: step,
              question: '',
              answers: [{ number: 1, value: this.selectedChampCode, text: this.selectedChampName }, { number: 2, value: this.selectedLane, text: laneText }],
            };
          }
      } else if (step === 2 && !this.isHigherTier) {
        const goalTierText = this.getTierStr(this.goalTier);
        answer = {
          number: step,
          question: '',
          answers: [{ number: 1, value: this.goalTier, text: goalTierText }],
        };
      } else if (step === 3 && !this.isHigherTier) {
        const answers = this.selectedItemIndex.map(i => {
          let value = '';
          let text = this.questionItems[i]?.text;

          if (i === 0) {
            text = this.selectedChampName;
          }
          if (i === 1) {
            text = this.$t(`coaching.MC_lol_position_${this.selectedLane.toLowerCase()}`);
          }

          if (this.questionItems[i]?.type === 'input') {
            value = this.friendsSummonerNickname;
          } else if (this.questionItems[i]?.type === 'text') {
            value = this.answerText;
          }
          return {
            number: i + 1,
            value,
            text,
          };
        });

        answer = {
          number: step,
          question: '',
          answers,
        };
      }
      this.$emit('answer', { step, answer });
    },
    setAnswer(_answer) {
      const { answers, number } = _answer;
      if (number === 1) {
        if (this.isHigherTier) {
          this.higherTierText = answers?.[0]?.value;
        } else {
          this.selectedChampName = answers?.[0]?.text;
          this.selectedChampCode = answers?.[0]?.value;
          this.selectedLane = answers?.[1]?.value;
        }
      } else if (number === 2 && !this.isHigherTier) {
        this.goalTier = answers?.[0]?.value;
      } else if (number === 3 && !this.isHigherTier) {
        this.selectedItemIndex = [];
        for (let i = 0; i < answers?.length; i++) {
          const idx = (answers[i] ?? {}).number - 1;

          if (this.questionItems[idx]?.type === 'input') {
            this.friendsSummonerNickname = answers[i]?.value;
            this.friendsSummonerBtnHandler();
          }

          if (this.questionItems?.[idx]?.type === 'text') {
            this.answerText = answers[i]?.value;
          }
          this.selectedItemIndex.push(idx);
        }
      }
      if (!this.isHigherTier) {
        number === 3 ? this.setOpenedQuestionNumber(number) : this.setOpenedQuestionNumber(number + 1);
        this.answeredQuestionNum.push(number);
      }
    },
    setHigherTierTextFocus() {
      this.$nextTick(() => {
        this.$refs?.highTierMemo?.onTipClick();
      });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[recommend-question-step-1] {
  .summoner-wrap {.rel;
    .riot-error {.abs; .w(100%); .h(100%); .flex; .justify-center; .items-center; .bgc(rgba(0, 0, 0, 0.8));
      > p {.c(#fff); .fs(16); .lh(23); text-align: center; font-weight: 500}
    }
  }
  .question-order-wrap {
    > li {.flex; .mb(40);}
    .left { .w(34); .mr(20); .min-w(34);
      &.inactive {.flex;}
      .order-number { .chakra; .fs(20); .c(#aaa); .fs(20);
        &.inactive {.o(0.2); .c(#444); }
        &.active {.c(#444); .fs(40);}
      }
      > img {.wh(34); .mt(6); }
    }
    .right { .w(100%);
      .lane-champ-select-wrap { .rel;
        .sub-text { .abs; .block; .fs(12); .c(#aaa); .t(-24); .l(0);}
        > p {.hide;}
        .lane-infos { .flex; gap: 4px;
          [lol-lane-info] {}
        }
        .champ-select-wrap { .mt(32); .flex; .items-center;
          > p {.fs(18); .c(#191919); .ml(8); }
        }
        .selected-lane { .hide; .pointer;
          > p {.fs(18); .c(#191919); .m(0, 20, 0, 8); }
          > img { .wh(48); }
        }

        &.closed { .flex;
          .sub-text {.hide;}
          .lane-infos {.hide;}
          .champ-select-wrap {.mt(0); }
          .selected-lane {.flex; .items-center; }
        }
      }

      .tier-select-wrap { .flex; flex-direction: column;
        > p {.fs(18); .c(#191919); .ml(8); }
        .goal-tier { .flex; .items-center; .pointer; .w(fit-content);
          &.disabled {pointer-events: none;
            > p {.o(0.2); }
          }
          > p {.fs(18); .c(#191919);
            &:first-of-type {}
          }
          .tier-holder {.wh(50); .flex; .justify-center; .items-center; .bgc(#fff); box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); .br(8); .m(0, 8);
            > img {.wh(45);}
          }
        }

        &.closed {
          [goal-tier-selector] {.hide;}
        }
      }
      .question-select-wrap {
        .question-select-text { .flex; .items-center; .h(48); .pointer; .w(fit-content);
          &.disabled {pointer-events: none;
            > p {.o(0.2); }
          }
          > p {.fs(18); .c(#191919); .mr(8); }
          > span {.fs(12); .c(#aaa); }
        }
        .questions { .flex; flex-direction: column; gap: 16px;}
        .question-item { .flex; gap: 16px; .min-w(160);
          .friends-summoner-wrap {.flex;
            > [basic-button] {.w(70); .pl(16); .pr(16);}
            .friends-summoner-info { .flex; .items-center;
              .tier-img-wrap {.br(8); .flex; .bgc(#eaeaea); .mr(4);
                > img {.wh(32);}
              }
            }
          }
          > [basic-button] {.fs(14); .lh(20); .p(0, 16); .w(fit-content); .min-w(160); .min-h(50); .h(unset);
            > pre {text-align: left;}
          }
          [underline-text-input] { .w(230); .mr(8);}
          [memo-text-area] {.h(98); .w(100%); .br(4);
            &.wrap-textarea > textarea {.h(unset);}
          }
        }

        &.closed {
          .questions {.hide;}
        }
      }
    }
  }
  .summoner-wrap { .flex; flex-direction: row; .items-center; .justify-center; .w(100%); .h(88); .bgc(#f6f6f6); .mb(48);

    .profile-text3 {.hide;}
    > h4 {.fs(18); .c(#191919); font-weight: normal; .m(0, 8); }
  }

  .higher-tier {
    .higher-tier-text { .flex; .mb(20); .items-center;
      > img {.wh(24); .mr(8); }
      > p {.fs(18); .c(#191919); }
    }

    [memo-text-area] {.h(98); .w(100%);
      &.wrap-textarea > textarea {.h(unset);}
    }
  }

  .btn-wrap {.abs; .b(40); .w(100%); .flex; .space-between;
    > [basic-button] { .h(40); .w(80);
      &.w-84 {.w(84);}
      p {.mr(4); .flex; .items-center; .fs(14);
        .emoji {.mr(4); }
        .arrow-dark {transform: rotate(90deg); .wh(18);}
        .arrow-white {transform: rotate(-90deg); .wh(18);}
      }
    }
  }

  @media (@ml-down) {
    .summoner-wrap { .mb(40); .flex; flex-direction: column; .p(24, 0); .h(unset);
      > h4 {.fs(16);}
      .profile-text1 {.hide;}
      .profile-text2 {.hide;}
      .profile-text3 {display: inherit; .mb(8);}
    }

    .higher-tier {
      .higher-tier-text {
        > p {.fs(14); }
      }
    }

    .question-order-wrap {
      .right {
        .lane-champ-select-wrap {
          .champ-select-wrap { .flex; flex-direction: column; align-items: unset;
            [champ-selector] { .w(100%); position: unset;
              &.on {
                .filter-champ-wrap {
                  .b(0); border-radius: 20px 20px 0 0;
                }
                .dim {display: unset;}
              }
              .champ-selector-inner { .w(unset); }
              .filter-champ-wrap { display: unset !important; .w(100%); transition: bottom 0.3s; .t(unset); position: fixed; .l(0); .b(-100%); .z(10000);
              }
              .champ-list {.h(60vh);}
            }
            > p {.mt(8); }
          }
          .lane-infos {
            flex-wrap: wrap;
          }
          &.closed { .flex; flex-wrap: wrap;
            .selected-lane { align-items: unset;}
            .champ-select-wrap {
              > p {.hide;}
            }
            > p { .fs(18); .c(#191919); display: unset; .mt(16);}
          }
        }
        .tier-select-wrap {.max-w(calc(100vw - 94px));}
        .question-select-wrap {
          .question-item {
            flex-direction: column;
            [basic-button] {.pt(12); .pb(12); .min-h(unset);}
            [underline-text-input] {.w(100%);}
          }
        }
      }
    }

    .btn-wrap { justify-content: unset;
      > [basic-button] {
        &.next {.ml(8);}
        p {.fs(14);}
      }
    }
  }
}
</style>
