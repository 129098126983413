<template>
  <div :class="{selected: selected || hover}" lol-lane-info @click="$emit('click', lane)" @mouseout="hover = false" @mouseover="hover = true">
    <div class="left-side">
      <img :src="posIcon" class="position-icon" />
    </div>
    <div class="right-side">
      <div class="top">
        <h4>{{ posText }}</h4>
        <span>{{ $t('_.MS_winning_rate') }}&nbsp;</span>
        <span>{{ winRate }}%</span>
      </div>
      <div class="bottom">
        <span>{{ totalWinCount }}</span>
        <span>&nbsp;{{ $t('_.MS_win') }}</span>
        <span class="bottom-divider" />
        <span>{{ totalLoseCount }}</span>
        <span>&nbsp;{{ $t('_.MS_loss') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { LOL_POSITION_ICON, LOL_POSITION_TEXT, LOL_POSITION_ICON_GREY } from '@/constants/lol.js';

export default {
  name: 'LolLaneInfo',
  lexicon: 'coaching',
  props: {
    selected: {
      type: Boolean, default: false,
    },
    lane: {
      type: String, default: 'TOP',
    },
    winRate: {
      type: Number, default: 0,
    },
    totalLoseCount: {
      type: Number, default: 0,
    },
    totalWinCount: {
      type: Number, default: 0,
    },
    pickRate: {
      type: Number, default: 0,
    },
    totalMatchCount: {
      type: Number, default: 0,
    },
  },
  data: () => ({
    hover: false,
  }),
  computed: {
    posIcon() {
      return this.selected || this.hover ? LOL_POSITION_ICON?.[this._lane] : LOL_POSITION_ICON_GREY?.[this._lane];
    },
    posText() {
      return this.$t(`_.${LOL_POSITION_TEXT?.[this._lane]}`);
    },
    _lane() {
      switch (this.lane) {
        case 'BOT':
          return 'AD_CARRY';
        case 'UITILITY':
          return 'SUPPORT';
        default:
          return this.lane;
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[lol-lane-info] { .flex; .min-w(125); .h(48); .bgc(#fff); .br(4); border: 1px solid #ddd; box-sizing: border-box; .p(8); overflow: hidden; .pointer;
  &.selected {
    border: 1px solid #191919;
    .right-side {
      .top {
        h4 {.c(#191919);}
        span {.c(#b18345);}
      }
    }
  }
  .left-side {.mr(4);
    img {.wh(32);}
  }
  .right-side { .c(#aaa);
    span {.fs(10); font-family: 'Roboto'; .lh(10);}
    .top { .flex; .items-center;
      h4 {.fs(12); .mr(4);}
      span:first-of-type {}
    }
    .bottom { .flex; .items-center;}
    .bottom-divider {.h(8); .w(0); .m(0, 4); border-right: 1px solid #ddd;}
  }
}

</style>
