<template>
  <div goal-tier-selector>
    <div v-for="(tier, i) in tierList" :key="i" :class="{active: hoverIndex >= i || selectedIndex >= i}" class="tier" @click="selectHandler(tier)" @mouseleave="hoverIndex = -1" @mouseover="hoverIndex = i">
      <div class="tier-box">
        <div class="gradient"></div>
        <img :src="`/img/lol/emblem-${tierList[i]}.svg`" :style="{bottom: `${i * 5 - 0.5}px`}" />
      </div>
      <div class="tier-label">
        <p>{{ $t(`_.${tierStrKey[i]}`) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TierSelector',
  lexicon: 'coaching',
  props: {
    value: {
      type: String, default: '',
    },
  },
  data: () => ({
    tierList: ['BRONZE', 'SILVER', 'GOLD', 'PLATINUM', 'DIAMOND', 'MASTER', 'GRANDMASTER', 'CHALLENGER'],
    tierStrKey: ['LOL_TIER_BRONZE_0', 'LOL_TIER_SILVER_0', 'LOL_TIER_GOLD_0', 'LOL_TIER_PLATINUM_0', 'LOL_TIER_DIAMOND_0', 'LOL_TIER_MASTER', 'LOL_TIER_GRANDMASTER', 'LOL_TIER_CHALLENGER'],
    hoverIndex: -1,
  }),
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(tier) {
        this.$emit('input', tier);
      },
    },
    selectedIndex() {
      return this.tierList.indexOf(this._value);
    },
  },
  methods: {
    selectHandler(tier) {
      this._value = tier;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[goal-tier-selector] { .flex; overflow-x: scroll; .pb(4);
  &::-webkit-scrollbar {
    height: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  .tier { .w(66); .mr(8); .min-w(66); cursor: pointer;
    &.active {
      .tier-box {
        box-sizing: border-box;
        .gradient {
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #b7ead8 100%);
        }
      }
      .tier-label {.bgc(#191919); .c(#fff);}
    }
    .tier-box { .h(80); .rel;
      > img {.abs; .z(1); .wh(45); .l(0); .r(0); .ml(auto); .mr(auto);}
      .gradient {.wh(100%); .abs; .l(0); .b(0); background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #eaeaea 53.12%);
        &:nth-of-type(1) {.h(47);}
        &:nth-of-type(2) {.h(50);}
        &:nth-of-type(3) {.h(55);}
        &:nth-of-type(4) {.h(60);}
        &:nth-of-type(5) {.h(65);}
        &:nth-of-type(6) {.h(70);}
        &:nth-of-type(7) {.h(75);}
        &:nth-of-type(8) {.h(80);}
      }
    }
    .tier-label {.h(22); .mt(4); .flex; .justify-center; .items-center; .w(100%); .bgc(#eaeaea); .br(2); overflow: hidden; .c(#777); .fs(10); .lh(14);}
  }
}

</style>
