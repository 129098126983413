<template>
  <div progress-bar>
    <div class="bar-wrap">
      <div :style="{width: `${_width}%`}" class="bar" />
    </div>
    <div><em>{{ value }}</em>/{{ maxValue }}</div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    value: { type: Number, default: 0 },
    maxValue: { type: Number, default: 100 },
  },
  computed: {
    _width() {
      return (this.value / this.maxValue) * 100;
    },
  },
};
</script>

<style lang="less" scoped>
@import '~@/less/coaching.less';

[progress-bar] { .flex; .w(100%); .items-center;
  .bar-wrap { .h(3); .rel; .w(100%); .bgc(#eaeaea); .mr(12);
    .bar {.h(100%); .abs; .t(0); .l(0); .bgc(#191919); transition: width ease-in-out 0.3s; }
  }
  div {font-family: 'Roboto'; .c(#777); .fs(12); .bold;
    em {.c(#191919);}
  }
}
</style>
