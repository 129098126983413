<template>
  <section recommend-question-step-5>
    <div class="items-text">
      <span>(중복 선택 가능)</span>
    </div>
    <div class="question-item-wrap">
      <div v-for="(item, i) in questionList" :key="i" class="question-item">
        <BasicButton :selected="selectedItemIndexList.includes(i)" @click="itemClickHandler(i)">{{ item }}</BasicButton>
      </div>
      <BasicButton v-if="showNextBtn" class="max-step" theme="dark" @click="$emit('nextBtn')">
        <p>
          <img class="emoji" src="/img/coaching/emoji-twinkle.svg">
          내게 딱 맞는 코치님 확인하기
          <img class="arrow-white" src="/img/icon/icon-arrow-down-white.svg" />
        </p>
      </BasicButton>
    </div>
    <div class="btn-wrap">
      <BasicButton theme="grey" @click="$emit('prevBtn')">
        <p>
          <img class="arrow-dark" src="/img/icon/icon-arrow-down-dark.svg" />
          이전
        </p>
      </BasicButton>
      <BasicButton v-if="showNextBtn" class="max-step" theme="dark" @click="$emit('nextBtn')">
        <p>
          <img class="emoji" src="/img/coaching/emoji-twinkle.svg">
          내게 딱 맞는 코치님 확인하기
          <img class="arrow-white" src="/img/icon/icon-arrow-down-white.svg" />
        </p>
      </BasicButton>
    </div>
  </section>
</template>

<script>
import BasicButton from '@/views/components/common/button/BasicButton.vue';

export default {
  name: 'RecommendQuestionStep5',
  components: { BasicButton },
  props: {
    step: {
      type: Number, default: 0,
    },
  },
  computed: {
    showNextBtn() {
      return this.selectedItemIndexList.length;
    },
  },
  data: () => ({
    selectedItemIndexList: [],
    questionList: ['게임코치아카데미 출신', '프로 경력', '장인', '스트리머'],
  }),
  methods: {
    itemClickHandler(i) {
      const idx = this.selectedItemIndexList.indexOf(i);
      if (idx > -1) {
        this.$delete(this.selectedItemIndexList, idx);
      } else this.selectedItemIndexList.push(i);
    },
    pushAnswer(step) {
      const answers = this.selectedItemIndexList.map(i => {
        const value = '';
        return { number: i + 1, value, text: this.questionList?.[i] };
      });
      const answer = { number: step, question: '', answers };
      this.$emit('answer', { step, answer });
    },
    setAnswer(_answer) {
      this.selectedItemIndexList = [];
      const { answers } = _answer;
      for (let i = 0; i < answers?.length; i++) {
        this.selectedItemIndexList.push((answers[i] ?? {}).number - 1);
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[recommend-question-step-5] {
  .items-text { .mb(12);
    > p {.fs(18); .c(#191919); .mr(8); }
    > span {.fs(12); .c(#aaa); }
  }

  .question-item-wrap { .flex; flex-wrap: wrap; row-gap: 16px; column-gap: 20px;
    .question-item { .flex;
      [basic-button] { .fs(14); .h(unset); .min-h(50); .pl(16); .pr(16);}
    }
    .max-step { .h(48); display: none; .w(100%); text-align: center;
      p {.mr(4); .flex; .items-center; .fs(14);
        .emoji {.mr(4); }
        .arrow-dark {transform: rotate(90deg); .wh(18);}
        .arrow-white {transform: rotate(-90deg); .wh(18);}
      }
    }

  }

  .btn-wrap {.abs; .b(40); .w(100%); .flex; .space-between;
    > [basic-button] { .h(40); .w(80);
      &.max-step {.w(250); .p(0);}
      &.w-84 {.w(84);}
      p {.mr(4); .flex; .items-center; .fs(14);
        .emoji {.mr(4); }
        .arrow-dark {transform: rotate(90deg); .wh(18);}
        .arrow-white {transform: rotate(-90deg); .wh(18);}
      }
    }
  }

  @media (@ml-down) {
    .question-item-wrap {
      .question-item { .flex; .w(100%);
        [basic-button] {.w(100%); .fs(14); .h(44); .min-h(unset);}
      }
      .max-step {display: flex;}
    }

    .btn-wrap {
      > [basic-button] { .pt(12); .pb(12);
        p {.fs(14);}
      }
      .max-step {display: none;}
    }
  }
}
</style>
