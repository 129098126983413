<template>
  <div recommend-questions>
    <div class="top-wrap">
      <div class="title-wrap">
        <template v-if="checkStep(1)">
          <h4>나에게 딱 맞는 코치님을 찾기위해</h4>
          <h4>
            <img src="/img/coaching/emoji-star.svg" />
            <em>목표를 설정해 볼까요?</em></h4>
        </template>
        <template v-if="checkStep(2)">
          <h4>
            <img src="/img/coaching/emoji-thinking.svg" />
            <em>어떤 방식으로 수업을&nbsp;</em>받고 싶나요?
          </h4>
        </template>
        <template v-if="checkStep(3) || checkStep(4)">
          <h4>
            <img src="/img/coaching/emoji-eyes-star.svg" />
            <em>어떤 스타일의 코치님&nbsp;</em>을 선호하세요?
          </h4>
        </template>
        <template v-if="checkStep(5)">
          <h4 class="block">
            <img src="/img/coaching/emoji-rainbow.svg" />
            <em>어떤 경력을 갖춘 코치님께&nbsp;</em>배우고 싶으세요?
          </h4>
        </template>
      </div>
      <div class="progress">
        <ProgressBar :maxValue="maxStep" :value="step" />
      </div>
    </div>
    <div class="question-content">
      <RecommendQuestionNoSummoner v-if="noSummoner" v-show="checkStep(1)" ref="noSummoner" :step="step" @answer="answerHandler" @nextBtn="nextBtnHandler" @prevBtn="prevBtnHandler" />
      <RecommendQuestionStep1 v-else v-show="checkStep(1)" ref="step1" v-bind="{summonerRankProfile, summonerLaneInfos, summonerChampInfos, isHigherTier, step, hasRiotError}" @answer="answerHandler" @nextBtn="nextBtnHandler" @nextStep="nextStep" @prevBtn="prevBtnHandler" @setStep="(v) => $emit('setStep', v)" />
      <RecommendQuestionStep2 v-show="checkStep(2)" ref="step2" :step="step" @answer="answerHandler" @nextBtn="nextBtnHandler" @prevBtn="prevBtnHandler" />
      <RecommendQuestionStep3 v-show="checkStep(3)" ref="step3" :step="step" @answer="answerHandler" @nextBtn="nextBtnHandler" @prevBtn="prevBtnHandler" />
      <RecommendQuestionStep4 v-show="checkStep(4)" ref="step4" :step="step" @answer="answerHandler" @nextBtn="nextBtnHandler" @prevBtn="prevBtnHandler" />
      <RecommendQuestionStep5 v-show="checkStep(5)" ref="step5" :step="step" @answer="answerHandler" @nextBtn="nextBtnHandler" @prevBtn="prevBtnHandler" />
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/views/components/common/ProgressBar.vue';
import RecommendQuestionStep1 from '@/views/components/coaching/recommend/RecommendQuestionStep1.vue';
import RecommendQuestionStep2 from '@/views/components/coaching/recommend/RecommendQuestionStep2.vue';
import RecommendQuestionStep3 from '@/views/components/coaching/recommend/RecommendQuestionStep3.vue';
import RecommendQuestionStep4 from '@/views/components/coaching/recommend/RecommendQuestionStep4.vue';
import RecommendQuestionStep5 from '@/views/components/coaching/recommend/RecommendQuestionStep5.vue';
import RecommendQuestionNoSummoner from '@/views/components/coaching/recommend/RecommendQuestionNoSummoner.vue';

export default {
  name: 'RecommendQuestions',
  lexicon: 'coaching',
  components: { RecommendQuestionStep5, RecommendQuestionStep4, RecommendQuestionStep3, RecommendQuestionStep1, RecommendQuestionStep2, ProgressBar, RecommendQuestionNoSummoner },
  props: {
    step: { type: Number, default: 1 },
    maxStep: { type: Number, default: 7 },
    summonerInfo: { default: {} },
    isHigherTier: { type: Boolean, default: false },
    answers: { type: Array, default: () => ([]) },
    hasRiotError: { type: Boolean, default: false },
  },
  watch: {
    answers: {
      immediate: true,
      handler(newVal) {
        if (newVal?.length) this.setAnswers();
      },
    },
  },
  computed: {
    noSummoner() {
      return !this.summonerInfo?.profile && !this.hasRiotError;
    },
    _summonerInfo() {
      return /** @type {SummonerRankInfoDetail} */ this.summonerInfo;
    },
    summonerRankProfile() {
      return this?._summonerInfo?.profile;
    },
    summonerLaneInfos() {
      const laneInfo = this._summonerInfo?.lane;
      const mappedLaneInfos = laneInfo?.map(lane => ({
        ...lane,
      }));
      return mappedLaneInfos;
    },
    summonerChampInfos() {
      return this._summonerInfo?.champions;
    },
  },
  methods: {
    checkStep(step) {
      if (!this.summonerRankProfile && !this.hasRiotError) return step === this.step;
      if (this.isHigherTier) return step === this.step;
      switch (step) {
        case 1:
          return [1, 2, 3].includes(this.step);
        default:
          return this.step === step + 2;
      }
    },
    prevBtnHandler() {
      window.scrollTo(0, 0);
      this.prevStep();
    },
    nextBtnHandler() {
      window.scrollTo(0, 0);
      this.nextStep();
    },
    nextStep() {
      const { step1, step2, step3, step4, step5, noSummoner } = this.$refs;

      switch (true) {
        case this.checkStep(1): {
          if (this.noSummoner) {
            noSummoner.pushAnswer();
            break;
          }
          if (this.isHigherTier) {
            step1.pushAnswer(1);
            step1.pushAnswer(2);
            step1.pushAnswer(3);
          } else if (this.step === 3) {
            step1.pushAnswer(1);
            step1.pushAnswer(2);
            step1.pushAnswer(3);
          } else step1.pushAnswer(this.step);
          break;
        }
        case this.checkStep(2): {
          step2.pushAnswer(4);
          break;
        }
        case this.checkStep(3): {
          step3.pushAnswer(5);
          break;
        }
        case this.checkStep(4): {
          step4.pushAnswer(6);
          break;
        }
        case this.checkStep(5): {
          step5.pushAnswer(7);
          break;
        }
        default:
          throw new Error('error');
      }

      if (this.step === this.maxStep) {
        this.$gtag.event(`click_coach_recommend_next_btn_step_last_${this.step}`, {
          'event_category': 'coach_recommend',
          'event_label': 'click_coach_recommend_next_btn',
          'value': this.answers,
        });
        this.$emit('answer', this.answers);
      }
      if (this.step < this.maxStep) {
        this.$gtag.event(`click_coach_recommend_next_btn_step_${this.step}`, {
          'event_category': 'coach_recommend',
          'event_label': 'click_coach_recommend_next_btn',
          'value': this.step,
        });
        this.$emit('nextStep');
      }
    },
    prevStep() {
      this.$emit('prevStep');
    },
    getChamps(keyword) {
      return this.$services.coaching.getLolChamps({ name: keyword });
    },
    answerHandler({ step, answer }) {
      if (this.answers?.[step - 1]) {
        this.answers[step - 1] = answer;
      } else this.answers.push(answer);
    },
    setAnswers() {
      this.$nextTick(() => {
        const { step1, step2, step3, step4, step5, noSummoner } = this.$refs;
        for (let i = 0; i < this.answers?.length; i++) {
          switch (i) {
            case 0:
            case 1:
            case 2:
              if (this.noSummoner && i === 0) {
                noSummoner.setAnswer(this.answers[i]);
                break;
              }
              if (!this.noSummoner) step1.setAnswer(this.answers[i]);
              break;
            case 3:
              step2.setAnswer(this.answers[i]);
              break;
            case 4:
              step3.setAnswer(this.answers[i]);
              break;
            case 5:
              step4.setAnswer(this.answers[i]);
              break;
            case 6:
              step5.setAnswer(this.answers[i]);
              break;
            default:
              throw Error('error');
          }
        }
      });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[recommend-questions] { .c(#191919); .min-h(713); .flex; flex-direction: column; .rel;
  .top-wrap {
    .title-wrap { .flex; flex-direction: column;
      > h4 { .flex; .justify-center; .items-center; font-weight: normal; .fs(24); .lh(34); white-space: nowrap;
        img { .wh(30); .mr(8);}
        em {.bold}
      }
    }
    .progress {.mt(57);}
  }
  .question-content { .mt(56); .mb(80); }

  @media (@ml-down) {
    .top-wrap {
      .title-wrap {
        > h4 {.fs(20);
          &.block {.block; text-align: center; white-space: normal;
          }
        }
      }
      .progress {
        .mt(49);
      }
    }
  }
}
</style>
